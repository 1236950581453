/* eslint-disable */
import { Component } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Utils, _, Images, Trans, Constant } from 'Helpers';
import { Banner, PitchLoader, SpaceAroundRow } from 'Components'
import { ReduxHOC } from 'HOC';
import lottie_loader from 'lottie_loader.json';

const ConditionalWrap = ({ condition, wrap, children }) => (
  condition ? wrap(children) : children
);


class PitchView extends Component {
  constructor(props) {
    const { LineupList, RosterMasterData, SelectedLeague } = props
    super(props);
    this.state = {
      RosterMasterData: RosterMasterData,
      LineupList: LineupList || [],
      SelectedLeague: SelectedLeague,
      lineup_formation_order: SelectedLeague && SelectedLeague.lineup_formation_order.split('-'),
      is_single_player: Utils.isSinglePlayer(),
      selectedPCF: '1'
    }

    this.node = null;
    this.setInstance = this.setInstance.bind(this)
  }

  setInstance(node) {
    this.node = node
    if (typeof this.props.getInstance === 'function') {
      this.props.getInstance({
        lineupGenerate: this.lineupGenerate
      })
    }
  }

  lineupGenerate = (type = '', is_sort_lineup = 0) => {
    let _LineupList = [];
    const { RosterMasterData, ALL_POSITION, ALL_POSITION_CLONE, LineupList, next_week, SelectedWeek } = this.props;

    const { is_single_player } = this.state;
    if (is_single_player) {
      switch (type) {
        case 'create':
          if (!Utils.getLineup() || _.isEmpty(Utils.getLineup())) {
            _.map(ALL_POSITION, (item, i) => {
              if (item.position === '') return null
              if (Constant.WEEK_JUMPED && next_week == 2 && (i == 0 || i == 1)) return null
              for (let index = 0; index < ((Constant.WEEK_JUMPED && next_week == 2) ? Number(item.max_player_per_position) : Number(item.number_of_players)); index++) {
                _LineupList.push(Utils.DefaultPlayer({
                  ...item,
                  position: _.cloneDeep(item.position.split('to')),
                  position_label: _.cloneDeep(item.position),
                  position_clone: _.cloneDeep(item.position)
                }))
              }
            })
          } else {
            _LineupList = Utils.getLineup()
          }
          break;
        case 'reset':
          _.map(ALL_POSITION, (item, i) => {
            if (item.position === '') return null
            if (Constant.WEEK_JUMPED && next_week == 2 && (i == 0 || i == 1)) return null
            for (let index = 0; index < (Constant.WEEK_JUMPED && next_week == 2 ? Number(item.max_player_per_position) : Number(item.number_of_players)); index++) {
              _LineupList.push(Utils.DefaultPlayer({
                ...item,
                position: _.cloneDeep(item.position.split('to')),
                position_label: _.cloneDeep(item.position),
                position_clone: _.cloneDeep(item.position),
              }))
            }
          })
          break;

        // case 'reset':
        default:
          let all_position = SelectedWeek == '' ? ALL_POSITION : ALL_POSITION_CLONE[SelectedWeek]
          if (is_sort_lineup != 0) {
            let Arr =
              [...Array(is_sort_lineup).fill({
                position: '184',
                rank: '184',
                player_uid: null
              })]
            let LineupListCopy = [...LineupList, ...Arr]

            for (let i = 0; i < LineupListCopy.length; i++) {
              for (let j = 0; j < all_position.length; j++) {
                if (Utils.PlayerPositionCheck(LineupListCopy[i].position, all_position) == all_position[j].position) {
                  _LineupList.push({
                    ...LineupListCopy[i],
                    position: Utils.PlayerPositionCheck(LineupListCopy[i].rank, all_position).split('to'),
                    position_label: Utils.PlayerPositionCheck(LineupListCopy[i].rank, all_position),
                    position_clone: Utils.PlayerPositionCheck(LineupListCopy[i].rank, all_position)
                  })
                  break;
                }
              }
            }
          } else {
            for (let i = 0; i < LineupList.length; i++) {
              for (let j = 0; j < all_position.length; j++) {
                if (Utils.PlayerPositionCheck(LineupList[i].position, all_position) == all_position[j].position) {
                  _LineupList.push({
                    ...LineupList[i],
                    position: Utils.PlayerPositionCheck(LineupList[i].rank, all_position).split('to'),
                    position_label: Utils.PlayerPositionCheck(LineupList[i].rank, all_position),
                    position_clone: Utils.PlayerPositionCheck(LineupList[i].rank, all_position)
                  })
                  break;
                }
              }
            }
          }
          break;
      }
      _LineupList.length && this.props.updateLineupList(_LineupList);
    } else {
      switch (type) {
        case 'create':
          var k = 1
          if (!Utils.getLineup() || _.isEmpty(Utils.getLineup())) {
            let default_formation_ls = Utils.getDefaultFormation() || RosterMasterData.default_formation
            _.map(RosterMasterData.lineup_formation.formation[default_formation_ls], (item, idx) => {
              for (let index = 0; index < Number(item.number_of_players); index++) {
                _LineupList.push(Utils.DefaultPlayer({ ...item }))
              }

              for (let index = 0; index < Number(item.number_of_bench_player); index++) {
                //  console.log(index, 'index');
                _LineupList.push(Utils.DefaultPlayer({ ...item, is_bench_player: 1, bench_order: k }))
                k++
              }
            })
          } else {
            _LineupList = Utils.getLineup()
          }
          break;
        case 'reset':
          var k = 1
          let default_formation_ls = Utils.getDefaultFormation() || RosterMasterData.default_formation
          _.map(RosterMasterData.lineup_formation.formation[default_formation_ls], (item, idx) => {
            for (let index = 0; index < Number(item.number_of_players); index++) {
              _LineupList.push(Utils.DefaultPlayer({ ...item }))
            }
            for (let index = 0; index < Number(item.number_of_bench_player); index++) {
              _LineupList.push(Utils.DefaultPlayer({ ...item, is_bench_player: 1, bench_order: (_.isUndefined(item.bench_order) || item.bench_order == 0 ? k : item.bench_order) }))
              k++
            }
          })
          break;
        default:
          _LineupList = LineupList
      }
      _LineupList.length && this.props.updateLineupList(_LineupList);
    }
  }

  RemovePlayer = (player) => {
    const { LineupList, IS_TRASFER, next_week, joined_week } = this.props;
    let param = {
      player: player,
      lineup: LineupList,
      joined_week,
      next_week
    }

    if (IS_TRASFER) {
      Utils.setTransferData(player, '0')
    }

    Utils.RemovePlayerFromLineup(param).then((response) => {
      this.props.updateLineupList(response);
    })
  }

  //For get player from roster list #optimize task
  getPlayerItem = (item) => {
    const Player = _.filter(this.props.MasterRosterList, (o) => o.player_uid == item.player_uid);
    return { ...(_.isEmpty(Player) ? {} : Player[0]), ...item }
  }


  UNSAFE_componentWillReceiveProps(nextProps) {

    if (this.state.RosterMasterData !== nextProps.RosterMasterData) {
      this.setState({
        RosterMasterData: nextProps.RosterMasterData
      })
    }
    if (this.props.lineup_master_id != nextProps.lineup_master_id) {
      // if (nextProps.lineup_master_id) {
      //   this.lineupGenerate()
      // } else {
      //   this.lineupGenerate('create')
      // }
    }
    if (this.state.LineupList !== nextProps.LineupList) {
      this.setState({
        MasterRosterList: nextProps.MasterRosterList
      }, () => {
        this.setState({
          LineupList: nextProps.LineupList
        })
      })
    }
  }

  changePCFhandler = (id) => {
    this.setState({
      selectedPCF: id
    })
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }
  render() {
    const { LineupList, lineup_formation_order, is_single_player, selectedPCF } = this.state;
    const {
      className,
      SelectedLeague,
      ALL_POSITION_CLONE,
      LineupLoader,
      SelectedWeek,
      next_week,
      toggleDrawer,
      hideBtn,
      root,
      message,
      team_info,
      FormationModalToggle,
      default_formation,
      IS_POINT,
      SwapActive,
      formation_show,
      joined_week,
      homeView,
      IS_MYTEAM,
      lineup_master_id,
      Trf,
      is_leagueD
    } = this.props;
    const _bannerData = _.filter(root.BannerData, o => o.ad_position_id == 3)
    const RosterTrigger = () => {
      return (
        <>
          {
            IS_MYTEAM && !_.isUndefined(lineup_master_id) && Utils.getJoined() !== 0 ?
              <div className={homeView ? 'hide-cl' : "my-info updated-minfo"} onClick={() => toggleDrawer()}>
                <i />
                <span className={homeView ? 'hide-cl' : 'players-txt'}>
                  <Trans>MY INFO</Trans>
                </span>
              </div>
              :
              Trf ?
                <div className={homeView ? 'hide-cl' : "roster-trigger"} onClick={() => toggleDrawer()}>
                  <i />
                  <span className={homeView ? 'hide-cl' : 'players-txt'}>
                    <Trans>ALL PLAYERS</Trans>
                  </span>
                </div>
                : Utils.getJoined() === 0 ?
                  <div className={homeView ? 'hide-cl' : "my-info updated-minfo"} onClick={() => toggleDrawer()}>
                    <i />
                    <span className={homeView ? 'hide-cl' : 'players-txt'}>
                      <Trans>ALL PLAYERS</Trans>
                    </span>
                  </div>
                  : <div className={homeView ? 'hide-cl' : "roster-trigger"} onClick={() => toggleDrawer()}>
                    <i />
                    <span className={homeView ? 'hide-cl' : 'players-txt'}>
                      <Trans>ALL PLAYERS</Trans>
                    </span>
                  </div>
          }
        </>
      )
    }
    const SpaceAroundRowProps = {
      is_single_player,
      selectedPCF,
      LineupList: LineupList,
      RemovePlayer: this.RemovePlayer,
      getPlayerItem: this.getPlayerItem,
      ...this.props
    }
    // console.log(selectedPCF,'ddss')

    return (
      <div ref={this.setInstance} className={homeView ? 'home-view' : `pitch-wrap-pos ${className} ${is_leagueD ? ' is-res-cls ' : '  '}` }>
        {/* {
          (!LineupLoader && formation_show && !IS_POINT) &&
          <div className={`formation-trigger ${Utils.getTransferData().out.length != 0 ? 'is_trasfer' : ''} ${SwapActive ? 'disabled' : ''}`} onClick={() => FormationModalToggle()}>
            {default_formation}
            {
              Utils.getTransferData().out.length == 0 &&
                <i className="icon-down" />
            }

          </div>
        } */}
        {homeView ?
          <div className="field-wall-banner home-edit">
            <div className="cell">
              <Banner type="4" />
            </div>
            <div className="cell mid"></div>
            <div className="cell">
              <Banner type="5" />
            </div>
          </div>
          : ''}
        {
          is_single_player ?
            <>
              <div className="single-player-pitch-view">
                <div className="field-wall">
                  <div className="league-title">{SelectedLeague.league_name}</div>
                  <div className="field-wall">
                    <div className="league-title">{SelectedLeague.league_name}</div>
                    <div className="field-wall-bottom">
                      <div className="small-adv">
                        <Banner type="11" />
                      </div>
                      <div className="wall-logo">
                        <img src={Images.LEAGUE_WALL_LOGO} alt="" />
                      </div>

                      <div className="small-adv">
                        <Banner type="11" />
                      </div>
                    </div>
                  </div>
                </div>
                {
                  LineupLoader || (_.isUndefined(ALL_POSITION_CLONE) || SelectedWeek == '' || _.isUndefined(LineupList)) ?
                    <PitchLoader />
                    :
                    <>
                      {
                        ALL_POSITION_CLONE[SelectedWeek].length > 2 ?
                          _.map(ALL_POSITION_CLONE[SelectedWeek], (item, idx) => {
                            if (SelectedWeek == 2 && idx == 0) return ''
                            return (
                              <ConditionalWrap key={idx} condition={idx == 2 || idx == 3}
                                wrap={children => (<div className='row-wrap'>{children}</div>)}>
                                <SpaceAroundRow position={item.position} {...SpaceAroundRowProps} key={idx} {...(ALL_POSITION_CLONE[SelectedWeek].length > 2 && (idx == 0 || idx == 1)) ? { className: 'space-between' } : ''} />
                              </ConditionalWrap>
                            )
                          })
                          :
                          <SpaceAroundRow {...SpaceAroundRowProps} />
                      }

                      {
                        _.isEmpty(LineupList) &&
                        <div className="blank-msz">
                          <span>{message}</span>
                        </div>
                      }
                    </>
                }
              </div>
            </>
            :
            <div className={homeView ? '' : is_leagueD && _.isEmpty(LineupList) ? 'pitch-view fallback-pitch' : `pitch-view sport_id_${SelectedLeague.sports_id}`}>
              {homeView ? ''
                :
                <div className="field-wall-banner">
                  <div className="cell">
                    <Banner type="4" />
                  </div>
                  <div className="cell mid"></div>
                  <div className="cell">
                    <Banner type="5" />
                  </div>
                </div>}

              <div className={homeView ? '' : 'mt-pitchvw'}>
                {
                  LineupLoader || (SelectedWeek == '' || _.isUndefined(LineupList)) ?
                    <Player
                      autoplay
                      loop
                      speed="1.5"
                      background='transparent'
                      src={lottie_loader}
                      style={{ height: '120px', width: '120px' }} />
                    :
                    <>
                      {
                        _.isEmpty(LineupList) &&
                        <div className="blank-msz">
                          <span>{message}</span>
                        </div>
                      }
                      {
                        !_.isEmpty(LineupList) &&
                        _.map(lineup_formation_order, (item, idx) => {
                          return (
                            <SpaceAroundRow position={item} {...SpaceAroundRowProps} key={idx} />
                          )
                        })
                      }
                    </>
                }
              </div>

            </div>
        }

        {
          LineupLoader ? <></> :
            (SelectedLeague && SelectedLeague.is_allow_bench == 1) &&
            <div className={is_leagueD && _.isEmpty(LineupList) && 'hide-img'}>
              {/* {homeView ? '' : <div className="bench-field-sap"><span><Trans>Players on Bench</Trans></span></div>} */}
              <SpaceAroundRow {...SpaceAroundRowProps} is_bench_player="1" LineupList={_.sortBy(this.state.LineupList, "bench_order")}
                className={(!_.isEmpty(team_info) && team_info.bench_booster_status.applied_week == SelectedWeek) ? 'active' : ''} />
            </div>
        }

        {!hideBtn &&
          <RosterTrigger />
        }

        {/* <div className="player-caption-filter">
          <div onClick={() => this.changePCFhandler(1)} {...{className: `cell ${selectedPCF == 1 ? 'active' : ''}`}}><Trans>Price</Trans></div>
          <div onClick={() => this.changePCFhandler(2)} {...{className: `cell ${selectedPCF == 2 ? 'active' : ''}`}}><Trans>Fixtures</Trans></div>
          <div onClick={() => this.changePCFhandler(3)} {...{className: `cell ${selectedPCF == 3 ? 'active' : ''}`}}><Trans>Date</Trans></div>
          <div onClick={() => this.changePCFhandler(4)} {...{className: `cell ${selectedPCF == 4 ? 'active' : ''}`}}><Trans>Total Pts</Trans></div>
        </div> */}

      </div>
    );
  }
}

PitchView.defaultProps = {
  SwapActive: false,
  IS_TRASFER: false,
  IS_POINT: false,
  IsLeaderboard: false,
  RosterMasterData: [],
  SelectedLeague: [],
  lineup_master_id: null,
  className: '',
  message: '',
  formation_show: false,
  toggleDrawer: () => { },
  getInstance: () => { },
  PlayerListNode: () => { },
  updateLineupList: () => { },
  FormationModalToggle: () => { }
}

export default ReduxHOC(PitchView);
