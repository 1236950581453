/* eslint-disable */
import { Component } from 'react';
import { Container, Row, Col, FormGroup, Input, Button, Form, Alert } from 'reactstrap'
import { LoginFormSM, SocialForm } from 'Components'
import { Utils, Http, API, Auth, Constant, withRouter, Trans } from 'Helpers'
import { ForgotPassword } from 'Modals';
import { ReduxHOC } from 'HOC';
import { Modal } from 'reactstrap'

const {
  REACT_APP_IS_DEMO_USE,
  REACT_APP_BASE_URL_PROD,
  REACT_APP_CLIENT_URL
} = process.env

const md5 = require('md5');
class SSOLogin extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      posting: false,
      formValid: false,
      isEye: false,
      email: "",
      password: "",
      ForgotPasswordShow: false,
      DemoDataShow: false,
      DemoData: ''
    }
  }
  gotoClienturl = (url) => {
    const originalUrl = REACT_APP_BASE_URL_PROD;
    const encodedUrl = encodeURIComponent(originalUrl);
    const finalUrl = `${url}?cbkurl=${encodedUrl}`;
    //console.log(finalUrl,"finalUrl");
    let status = Utils.isNativeWeb()
    switch(status){
      case 0:
        window.open(finalUrl, "_self")
        break;
      case 1:
        window.mobileApp.nativeLogin()
        break;
      case 2:
        window.webkit.messageHandlers.nativeLogin.postMessage("IOS")
        break;
      default:
        break;
    }
  }
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  render() {
    const { toggleForm, t, isOpen } = this.props

    const FormProps = {
      toggleForm: this.toggleForm,
    }


    return (
      <Modal isOpen={isOpen} toggle={() => toggleForm()} className='custom-modal onboard-modal' size={'sm'}>
        {/* <i className="close-btn icon-cancel" onClick={() => toggleForm()} />
        <div>
        <div className="landing-form popup">
          <Row className='onboarding-gutter-fix'>
            <Col>
              <h4 className='landing-form-title head-sso-f'><Trans>Welcome! Please log in to select your players and start playing.</Trans></h4>
              
            </Col>
          </Row>
          <Row className='m-t-20 onboarding-gutter-fix'>
            <Col>
              <Button type='submit' className='btn-block' onClick={()=>this.gotoClienturl(REACT_APP_CLIENT_URL)}><Trans>Login</Trans></Button>
            </Col>
          </Row>
        </div>
      

      </div> */}
        <LoginFormSM {...FormProps} />
      </Modal>

    )
  }
}
SSOLogin.defaultProps = {
  onTeamSubmit: () => { },
  closeModal: () => { },
  isGuestUser: false
}
const SSOLoginWraped = withRouter(ReduxHOC(SSOLogin));

export default SSOLoginWraped;



