/* eslint-disable */
import { Component, Suspense } from 'react';
import { CreateJoinButton, AllLeague, Banner, Booster } from 'Components';
import { _, Utils, Trans } from 'Helpers';
import { ReduxHOC } from 'HOC';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
const {
  REACT_APP_PRIMARY_COLOR
} = process.env

class LineupInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedLeague: Utils.getMultiLeg(),
      CurrentLineup: props.root.CurrentLineup,
      is_single_player: Utils.isSinglePlayer(),
      my_performance: props.my_performance,
      team_info: props.team_info,
      updateBooster: new Date().valueOf(),
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.updateLineupInfo != nextProps.updateLineupInfo) {
      this.setState({
        my_performance: nextProps.my_performance,
        team_info: nextProps.team_info
      })
    }
  }

  _isBoosterApplied = ({
    team_info,
    SelectedWeek,
    updateBooster
  }) => {
    return (team_info.bench_booster_status.is_applied != 0 && team_info.bench_booster_status.applied_week == SelectedWeek)
      || (team_info.free_hit_booster_status.is_applied != 0 && team_info.free_hit_booster_status.applied_week == SelectedWeek)
      || (team_info.captain_booster_status.is_applied != 0 && team_info.captain_booster_status.applied_week == SelectedWeek)
      || (team_info.wildcard_status.is_applied != 0 && team_info.wildcard_status.applied_week == SelectedWeek)

    // if ((team_info.bench_booster_status.is_applied != 0 && team_info.bench_booster_status.applied_week == SelectedWeek)
    // || (team_info.free_hit_booster_status.is_applied != 0 && team_info.free_hit_booster_status.applied_week == SelectedWeek)
    // || (team_info.captain_booster_status.is_applied != 0 && team_info.captain_booster_status.applied_week == SelectedWeek)
    // || (team_info.wildcard_status.is_applied != 0 && team_info.wildcard_status.applied_week == SelectedWeek)) {
    //   if(updateBooster == new Date().valueOf()) {
    //     this.props.updateBoosterFn(new Date().valueOf())
    //     return true
    //   }
    // }

  }

  render() {
    const {
      updateLineupInfo,
      SelectedWeekObj,
      user_join_count,
      BoosterProps,
      scoring_weeks
    } = this.props
    const {
      my_performance,
      team_info,
      CurrentLineup,
    } = this.state;

    const xAxis = _.map(scoring_weeks, (obj) => '')
    const yAxisSelf = _.map(scoring_weeks, (obj) => Number(obj.total_score))
    const chartOptions = {
      title: {
        text: ''
      },
      chart: {
        height: 250,
        zoomType: "xy",
        backgroundColor: "transparent",
      },
      legend: {
        itemStyle: { "color": "#666", "fontSize": "12px" },
        itemHoverStyle: { "color": "#ccc" },
        symbolWidth: 40,
      },
      xAxis: {
        categories: xAxis,
      },
      yAxis: {
        labels: {
          style: {
            color: 'rgb(105,105,105)'
          }
        }, gridLineColor: 'rgb(105,105,105)',
        title: {
          text: 'Fantasy Score'
        }
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          var value = '<div style="background-color:' + '"><span><b>Performance<span>' + this.x + '</span></b><br/> ' + 'Pts ' + this.y + '</span><div>';
          return value;
        },
        backgroundColor: "$primary",
        color: '#FFFFFF',
        style: {
          color: "#FFFFFF"
        }
      },
      series: [
        {
          name: team_info.team_name,
          color: "#FFFFFF",
          data: yAxisSelf,
        },
      ]
    }

    return (
      <>
        <div className="lineup-info-wrap">
          <div className="titlle-header">
            <div className="label-text"><Trans>My Info</Trans></div>
            <div className="value-text">{team_info.team_name || 'Team Name Here!'}</div>
          </div>

          <div className="lineup-info-box">
            <div className="inner-header"><Trans>Points/Rankings</Trans></div>
            <div className="inner-contant">
              <div className="flx-box">
                <div className="value">{SelectedWeekObj.week_rank || '--'}</div>
                <div className="label"><Trans>Overall Rank</Trans></div>
              </div>
              <div className="flx-box">
                <div className="value">{SelectedWeekObj.total_score || '--'}</div>
                <div className="label"><Trans>Overall Points</Trans></div>
              </div>

            </div>

            {
              this._isBoosterApplied(BoosterProps) &&
              <>
                <div className="inner-header"><Trans>Boosters</Trans></div>
                <div className="inner-contant no-padding-v">
                  {
                    user_join_count == 1 &&
                    <Booster {...BoosterProps} read_only />
                  }
                </div>
              </>
            }

          </div>
          <div className="inner-header"><Trans>Overall performance</Trans></div>
          <Suspense fallback={<div />}>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
              className="hcharts-color-fix"
            />
          </Suspense>


        </div>
        {/* <Banner type="7" paddingTop /> */}
      </>
    );
  }
}

export default ReduxHOC(LineupInfo);
