/* eslint-disable */
import { ShareButton } from 'Components';
import { _, API, Constant, Http, Utils, withRouter, Trans, Images } from 'Helpers';
import { Component, Fragment } from 'react';
import { ReduxHOC } from 'HOC';

class AllLeague extends Component {
  constructor(props) {
    super(props);
    const { t } = props
    this.state = {
      posting: true,
      SelectedLeague: Utils.getMultiLeg(),
      CurrentLineup: props.root.CurrentLineup,
      is_single_player: Utils.isSinglePlayer(),
      PrivateLeaderboardList: [],
      GlobalLeaderboardList: [],
      MonthlyLeaderboardList: [],
      current_week: '',
      fullChow: false,

      LeagueType: [
        { value: 'all_league', label: t('All Leagues') },
        { value: 'global_league', label: t('Global Leagues') },
        { value: 'global_paid_league', label: t('Global Paid Leagues') },
        { value: 'other_league', label: t('Private Leagues') },
        { value: 'mini_league', label: t('Mini Leagues') }
      ]
    }
    this.node = null;
    this.setInstance = this.setInstance.bind(this)
  }
  setInstance(node) {
    this.node = node
    if (typeof this.props.getInstance === 'function') {
      this.props.getInstance({
        GetAllLeague: this.GetAllLeague
      })
    }
  }

  gotoDetails = (data, index, alldata, pr_leaguedata) => {
    const {
      current_week,
      SelectedLeague } = this.state;
    const { league_id, sports_id } = SelectedLeague;
    setTimeout(() => {
      if ((Number(data.contest_info.joined_week) > current_week)) {
        Utils.notify(Constant.LEAGUE_OOPS_MSZ, "info", 3000);
      } else {
        const prv = _.isString(index) ? 0 : 1
        this.props.history.push({
          pathname: '/leagues/details',
          search: `?league_id=${league_id}&sports_id=${sports_id}&contest_id=${data.contest_id}&module_id=${data.module_id}&type=3&prv=${prv}`,
          state: { alldata: alldata, data: data, pr_leaguedata: pr_leaguedata, index }
        })
      }
    }, 100);
  }
  gotoDetailss = (data, index, alldata, pr_leaguedata, paid_league) => {
    const {
      current_week,
      SelectedLeague } = this.state;
    const { league_id, sports_id } = SelectedLeague;
    setTimeout(() => {
      if ((Number(alldata.contest_info.joined_week) > current_week) && (index == 'gameweek_standing' || index == 'country_standing' || index == 'fanclub_standing')) {
        Utils.notify(Constant.LEAGUE_OOPS_MSZ, "info", 3000);
      } else {
        const prv = _.isString(index) ? 0 : 1
        this.props.history.push({
          pathname: '/leagues/details',
          search: `?league_id=${league_id}&sports_id=${sports_id}&contest_id=${data.contest_id}&module_id=${data.module_id}&type=3&prv=${prv}`,
          state: { alldata: alldata, data: data, pr_leaguedata: pr_leaguedata, dta: true, paid_league, index }
        })
      }
    }, 100);
  }
  // For All other leagues
  init = async () => {
    const { SelectedLeague } = this.state;
    const { league_id, sports_id } = SelectedLeague;
    let param = {
      "sports_id": sports_id,
      "league_id": league_id,
      "user_id": this.props.user_id ? this.props.user_id : '' // For Point screen
    }
    let [PrivateLeaderboardList, MiniLeagueLeaderboardList] = await Promise.all([
      Http.post(API.GET_USER_LEADERBOARD_LIST_PRIVATE, param),
      Http.post(API.GET_USER_LEADERBOARD_LIST_MINILEAGUE, param),
    ]);
    return {
      ...PrivateLeaderboardList.data,
      ...MiniLeagueLeaderboardList.data,
    }
  }
  // For Global only
  initg = async () => {
    const { SelectedLeague } = this.state;
    const { league_id, sports_id } = SelectedLeague;
    let param = {
      "sports_id": sports_id,
      "league_id": league_id,
      "user_id": this.props.user_id ? this.props.user_id : '' // For Point screen
    }
    let [GlobalLeaderboardList] = await Promise.all([
      Http.post(API.GET_USER_LEADERBOARD_LIST_GLOBAL, param)
    ]);
    return {
      current_week: GlobalLeaderboardList.data.current_week || Utils.getActive(),
      ...GlobalLeaderboardList.data,
    }
  }
  GetAllLeague = () => {
    this.init().then(res => {
      this.setState({
        LeaderboardList: res,
        current_week: res.current_week,
        posting: false
      })
    }, () => {
      this.initg().then(res => {
        this.setState({
          LeaderboardListG: res,
          posting: false
        })
      })
    })

  }
  shouldComponentUpdate(nextProps) {
    if (nextProps.updateLineupInfo !== this.props.updateLineupInfo) {
      return false;
    } else {
      return true;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.updateLineupInfo != nextProps.updateLineupInfo) {
      this.GetAllLeague()
    }
  }

  componentDidMount() {
    const { extend } = this.props
    if (extend) {
      this.init().then(res => {
        this.setState({
          LeaderboardList: res,
          current_week: res.current_week,
          posting: false
        })
      })
      this.initg().then(res => {
        // console.log(res, 'asdasd')
        this.setState({
          LeaderboardListG: res,
          // current_week: res.current_week,
          posting: false
        })
      })
    }
  }
  PrizesUp = (contest_id) => {
    this.props.history.push(`/prizes/${contest_id}`)
  }

  getCatName = (str) => {
    const { LeagueType } = this.state;

    let _arr = _.filter(LeagueType, o => o.value == str)
    return !_.isEmpty(_arr) ? _arr[0].label : 'Leagues'


  }

  isEmptyObject = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  render() {
    const { className, extend, selected, hideLEeague } = this.props;
    const {
      posting,
      LeaderboardList,
      CurrentLineup,
      LeaderboardListG
    } = this.state;


    return (
      <div {...{ className: ` ${className}` }} ref={this.setInstance}>
        {
          posting ?
            <div className="spinner-static">
              <Utils.Spinner primary />
            </div>
            :
            <>
              {/* for Global >>*/}
              {!hideLEeague &&
                <Fragment>
                  {LeaderboardListG &&
                    <div className={`lineup-leaderboard`}>
                      <div className={`title`}><span><Trans>Global Leagues</Trans></span><div className='full-league'><Trans>full Season</Trans></div></div>
                      <div className='leaderboard-list'>
                        <div className={`leaderboard-item head `}>
                          {
                            !extend &&
                            <div className="cell fixw-l"><span className="lbl"><Trans>Rank</Trans></span></div>
                          }
                          {extend && <div className='cell fixw-l hide-in-sm'>Weeks</div>}
                          <div className="cell"><span className="lbl"><Trans>League</Trans></span></div>
                          {
                            extend &&
                            <>
                              <div className="cell fixw-l text-center"><span className="lbl"><Trans>Rank</Trans></span></div>
                              {/* <div className="cell fixw-l text-center"><span className="lbl"><Trans>Last Rank</Trans></span></div> */}
                              <div className="cell fixw-l text-center"><span className="lbl"><Trans></Trans></span></div>
                            </>
                          }
                          {/* <div className="cell fixw"></div> */}
                        </div>
                        {
                          _.map(LeaderboardListG['global_league'], (item, index) => {
                            {/* console.log('object') */ }
                            {/* console.log(item, 'itemopias') 
                            console.log(LeaderboardListG['contest_info'],'LeaderboardListG[') */}
                            {/* if (_.isUndefined(item)) return '' */}
                            if (_.isUndefined(item) || _.isEmpty(item)) return ''
                            if (!_.isUndefined(item.is_country_set) && item.is_country_set == 0) return ''
                            return (
                              <div className="leaderboard-item" key={index}>
                                {
                                  !extend &&
                                  <div className="cell fixw-l">{item.rank || '--'}</div>
                                }
                                <div className="cell league-wk-name" >
                                  {
                                    extend &&
                                    item.from_week ? 
                                    <div className="cell fixw-l tls gw-font 111111">{Utils.getWeekLabel(Utils.getMultiLeg().week_abbr['1'])}  {item.from_week} - {item.to_week}</div>
                                    :
                                    <div className="cell fixw-l tls gw-font 111111">{Utils.getWeekLabel(Utils.getMultiLeg().week_abbr['1'])}  {LeaderboardListG['contest_info'] && LeaderboardListG['contest_info'].start_week} - {LeaderboardListG['contest_info'] && LeaderboardListG['contest_info'].end_week}</div>
                                  }
                                  <span className='pointer-league elipsis-text' onClick={() => this.gotoDetailss(item, index, LeaderboardListG, LeaderboardList)}>
                                    {!_.isUndefined(item) ? (item.category_name || item.contest_name) : '--'}
                                  </span>{
                                    item.is_owner == 1 && (!_.isString(index) && CurrentLineup.week_info.last_week != CurrentLineup.week_info.active_week) &&
                                    <ShareButton item={item} />
                                  }

                                </div>
                                {
                                  extend &&
                                  <>
                                    <div className="cell fixw-l text-center pr-t">
                                      {(Utils.getActive() > parseInt(Utils.getJoined())) && item.rank !== 0 &&
                                        <span>
                                          {item.last_rank < item.rank && item.last_rank !== '0' ?
                                            < img src={Images.DOWN_ICN} className='icon-arrows' alt='up-down-ranks' />
                                            :
                                            < img src={Images.UP_ICN} className='icon-arrows' alt='up-down-ranks' />
                                          }
                                        </span>}
                                      <span id='rank-itm'>
                                        {item.rank || '--'}
                                      </span>
                                      {LeaderboardListG['contest_info'] && (item.rank <= LeaderboardListG['contest_info'].max_winning_rank && item.rank !== 0) && (Utils.getActive() > parseInt(Utils.getJoined())) && <img src={Images.TROPHY_IC} alt='trophy ic' className='pos-update' />}
                                    </div>
                                    {LeaderboardListG['contest_info'] && !_.isEmpty(LeaderboardListG['contest_info'].prize_distibution_detail) ?
                                      <div className="cell fixw-l text-center prize-tag" onClick={() => this.PrizesUp(item.contest_id)}>PRIZES</div>
                                      :
                                      <div className="cell fixw-l text-center"></div>
                                    }
                                  </>
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                      {!_.isUndefined(LeaderboardListG['global_paid_league']) || !_.isEmpty(LeaderboardListG['global_paid_league']) &&
                        <div className={`title`}><span><Trans>Global Leagues</Trans></span><div className='full-league'>(Full Season)</div>
                        </div>
                      }
                      {
                        _.map(LeaderboardListG['global_paid_league'], (item, index) => {
                          {/* console.log('object') */ }
                          if (_.isUndefined(item) || _.isEmpty(item)) return ''
                          return (
                            <div className="leaderboard-item" key={index}>
                              {
                                !extend &&
                                <div className="cell fixw-l">{item.rank || '--'}</div>
                              }
                              <div className="cell league-wk-name" >
                                {
                                  extend &&
                                  <div className="cell fixw-l tls gw-font radheee">{Utils.getWeekLabel(Utils.getMultiLeg().week_abbr['1'])}  {item.start_week} - {item.end_week}</div>
                                }
                                <span className='pointer-league elipsis-text' onClick={() => this.gotoDetailss(item, index, LeaderboardListG, LeaderboardList, true)}>
                                  {!_.isUndefined(item) ? (item.category_name || item.contest_name) : '--'}
                                </span>{
                                  item.is_owner == 1 && (!_.isString(index) && CurrentLineup.week_info.last_week != CurrentLineup.week_info.active_week) &&
                                  <ShareButton item={item} />
                                  // <span className='icon-share ' onClick={() => this.shraeModalInit(item)}></span>
                                }

                              </div>
                              {
                                extend &&
                                <>
                                  <div className="cell fixw-l text-center pr-t">
                                    {/* {((item.rank !== '0' || item.rank !== 0) && item.rank !== '') && (Utils.getActive() > parseInt(Utils.getJoined())) &&
                                              <span>
                                                {item.last_rank < item.rank && item.last_rank !== '0' && item.rank !== '' ?
                                                  < img src={Images.DOWN_ICN} className='icon-arrows' alt='up-down-ranks' />
                                                  :
                                                  < img src={Images.UP_ICN} className='icon-arrows' alt='up-down-ranks' />
                                                }
                                              </span>} */}
                                    {(Utils.getActive() > parseInt(Utils.getJoined())) && item.rank !== 0 &&
                                      <span>
                                        {item.last_rank < item.rank && item.last_rank !== '0' ?
                                          < img src={Images.DOWN_ICN} className='icon-arrows' alt='up-down-ranks' />
                                          :
                                          < img src={Images.UP_ICN} className='icon-arrows' alt='up-down-ranks' />
                                        }
                                      </span>}
                                    <span id='rank-itm'>
                                      {item.rank || '--'}
                                    </span>
                                    {(item.rank <= item.max_winning_rank && item.rank !== 0) && (Utils.getActive() > parseInt(Utils.getJoined())) && <img src={Images.TROPHY_IC} alt='trophy ic' className='pos-update' />}
                                    {/* {((item.rank <= item.contest_info.max_winning_rank) && (Utils.getActive() > parseInt(Utils.getJoined()))) && <img src={Images.TROPHY_IC} alt='trophy ic' id='trophy-ics' />} */}
                                  </div>
                                  {/* <div className="cell fixw-l text-center">{item.last_rank || '--'}</div> */}
                                  {!_.isEmpty(item.prize_distibution_detail) ?
                                    <div className="cell fixw-l text-center prize-tag" onClick={() => this.PrizesUp(item.contest_id)}>PRIZES</div>
                                    :
                                    <div className="cell fixw-l text-center"></div>
                                  }
                                </>
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  }
                </Fragment>}


              {/* For Private and others >>*/}
              {_.map(LeaderboardList, (Leaderboard, idx) => {

                return (

                  <Fragment key={idx}>
                    {
                      (selected.value == "all_league" || selected.value == idx) &&
                      <div className={`lineup-leaderboard ${this.isEmptyObject(Leaderboard) && selected.value == 'all_league' ? 'none' : ''}`}>

                        <div className={`title ${this.getCatName(idx) === 'Global Paid Leagues' ? 'n-data' : ''}`}><span>{this.getCatName(idx)}</span>{this.getCatName(idx) === 'Global Leagues' && <div className='full-league'>(Full Season)</div>}</div>
                        <div className="leaderboard-list">
                          <div className={`leaderboard-item head ${this.isEmptyObject(Leaderboard) || !_.isEmpty(Leaderboard.overall_standing) == '' ? 'n-data' : ''}`}>
                            {
                              !extend &&
                              <div className="cell fixw-l"><span className="lbl"><Trans>Rank</Trans></span></div>
                            }
                            {extend && <div className='cell fixw-l hide-in-sm'>Weeks</div>}
                            <div className="cell"><span className="lbl"><Trans>League</Trans></span></div>
                            {
                              extend &&
                              <>
                                <div className="cell fixw-l text-center"><span className="lbl"><Trans>Rank</Trans></span></div>
                                {/* <div className="cell fixw-l text-center"><span className="lbl"><Trans>Last Rank</Trans></span></div> */}
                                <div className="cell fixw-l text-center"><span className="lbl"><Trans></Trans></span></div>
                              </>
                            }
                            {/* <div className="cell fixw"></div> */}
                          </div>

                          {
                            !_.isEmpty(Leaderboard) ?
                              <>
                                {
                                  _.map(Leaderboard, (item, index) => {
                                    if (_.isUndefined(item.contest_info)) return ''
                                    return (
                                      <div className="leaderboard-item" key={index}>
                                        {
                                          !extend &&
                                          <div className="cell fixw-l">{item.rank || '--'}</div>
                                        }
                                        <div className="cell league-wk-name" >
                                          {
                                            extend &&
                                            <div className="cell fixw-l tls gw-font 2222222">{Utils.getWeekLabel(Utils.getMultiLeg().week_abbr['1'])}  {item.contest_info && item.contest_info.start_week} - {item.contest_info && item.contest_info.end_week}</div>
                                          }
                                          <span className='pointer-league elipsis-text' onClick={() => this.gotoDetails(item, index, LeaderboardListG, LeaderboardList)}>
                                            {!_.isUndefined(item.contest_info) ? (item.category_name || item.contest_info.contest_name) : '--'}
                                          </span>{
                                            item.is_owner == 1 && (!_.isString(index) && CurrentLineup.week_info.last_week != CurrentLineup.week_info.active_week) &&
                                            <ShareButton item={item} />
                                            // <span className='icon-share ' onClick={() => this.shraeModalInit(item)}></span>
                                          }

                                        </div>
                                        {
                                          extend &&
                                          <>
                                            <div className="cell fixw-l text-center pr-t">
                                              {(Utils.getActive() > parseInt(Utils.getJoined())) && item.rank !== 0 &&
                                                <span>
                                                  {item.last_rank < item.rank && item.last_rank !== '0' ?
                                                    < img src={Images.DOWN_ICN} className='icon-arrows' alt='up-down-ranks' />
                                                    :
                                                    < img src={Images.UP_ICN} className='icon-arrows' alt='up-down-ranks' />
                                                  }
                                                </span>}
                                              <span id='rank-itm'>
                                                {item.rank || '--'}
                                              </span>
                                              {(item.rank <= item.contest_info.max_winning_rank && item.rank !== 0) && (Utils.getActive() > parseInt(Utils.getJoined())) && <img src={Images.TROPHY_IC} alt='trophy ic' className='pos-update' />}
                                              {/* {((item.rank <= item.contest_info.max_winning_rank) && (Utils.getActive() > parseInt(Utils.getJoined()))) && <img src={Images.TROPHY_IC} alt='trophy ic' id='trophy-ics' />} */}
                                            </div>
                                            {/* <div className="cell fixw-l text-center">{item.last_rank || '--'}</div> */}
                                            {!_.isEmpty(item.contest_info.prize_distibution_detail) ?
                                              <div className="cell fixw-l text-center prize-tag" onClick={() => this.PrizesUp(item.contest_id)}>PRIZES</div>
                                              :
                                              <div className="cell fixw-l text-center">
                                                {/* <i className='icon-share' /> */}
                                                {item.contest_info.contest_access_type === '1' &&
                                                  <ShareButton item={item} />}
                                              </div>
                                            }
                                          </>
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </>
                              :
                              <h5 className='no-data-league'><Trans> No Data Available</Trans></h5>
                          }
                        </div>
                      </div>
                    }
                  </Fragment>
                )
              })}
            </>
        }
      </div >
    );
  }
}

AllLeague.defaultProps = {
  updateLineupInfo: null,
  extend: false,
  className: '',
  selected: { value: 'all_league', label: 'All Leagues' }
}

export default ReduxHOC(withRouter(AllLeague, { withRef: true }))
