/* eslint-disable eqeqeq */
import { Component, Fragment } from 'react';
import { _, Images, Constant, Utils, Trans } from 'Helpers';
import { Img } from 'Components';
const {
  REACT_APP_SALARY_SUBFFIX
} = process.env

class FieldPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_single_player: Utils.isSinglePlayer(),
      constant_pos: Utils.getFormationInitial(),
      AppMasterData: props.root.AppMasterData,
    }
  }

  render() {
    const {
      isDefault,
      RemovePlayer,
      item,
      LineupStage,
      PlayerListNode,

      SwapInit,
      SwapExecute,
      PlayerCardInit,
      IS_TRASFER,
      next_week,
      joined_week,
      team_info,
      SelectedWeek,
      last_week,
      IS_POINT,
      formation_show,
      SwapActive,
      // selectedPCF
    } = this.props;

    const { is_single_player,
      AppMasterData
      // , constant_pos
    } = this.state
    return (
      <>
        <div className="space-around" onClick={item.highlight ? () => SwapExecute(item) : null}>
          <div className={`field-player${isDefault ? ' default' : ''}${item.highlight ? ' highlight' : ''}${item.highlight_out ? ' highlight_out' : ''}${item.opacity ? ' opacity' : ''}
        
        ${!_.isEmpty(team_info) && (team_info.captain_booster_status.applied_week == SelectedWeek && item.player_role == 1) ? 'captian-booster-aaply' : ''}
        `}
            title={!isDefault ? (item.full_name) : ''}
            // title={!isDefault ? (item.full_name + ' | ' + item.team_name) : ''}
            onClick={
              (
                isDefault && (
                  _.isNull(joined_week) ||
                  (next_week == joined_week) ||
                  IS_TRASFER
                )
              ) ? () => PlayerListNode(item) : null}>

            <span onClick={isDefault || item.highlight || item.opacity ? null : () => PlayerCardInit(item)}>
              <figure className={`img ${isDefault ? 'default' : ''}`}>
                {
                  isDefault ?
                    <img src={Images.DEFAULT_JERSEY} alt="" />
                    :
                    <>
                      <Img src={(is_single_player ? item.player_image : item.jersey)} fallbacksrc={Images.DEFAULT_JERSEY} alt="" />
                      {
                        // !is_single_player &&
                        // <span className="pos-abbr-jersey">{item.position}</span>
                      }
                    </>
                }
              </figure>
              {
                (!is_single_player && !isDefault) &&
                <b className="pos-abbr">{item.position_label}</b>
              }
              {
                isDefault && (
                  _.isNull(joined_week) ||
                  (next_week == joined_week) ||
                  IS_TRASFER
                ) &&
                <span className="player-add">+</span>
              }
            </span>
            {
              !isDefault &&
              <Fragment>
                {
                  LineupStage != '' &&
                  <Fragment>
                    {
                      (formation_show && !IS_POINT && Utils.getTransferData().out.length == 0) &&
                      <img className="swap-icon" src={Images.SWAP_ICON} alt="" onClick={!item.highlight ? () => SwapInit(item) : null} />
                    }
                    {
                      (
                        _.isNull(joined_week) ||
                        (next_week == joined_week) ||
                        IS_TRASFER
                      ) && !SwapActive && !IS_POINT &&
                      <span className="player-remove" onClick={() => RemovePlayer(item)}>-</span>
                    }
                    {

                      !_.isUndefined(item.player_role) &&
                      item.player_role != 0 &&
                      <img className="player-role" src={item.player_role == 1 ? Images.CAP : Images.VC} alt="" />
                    }{
                      !_.isUndefined(item.is_substitute) &&
                      item.is_substitute != 0 &&
                      <img className="player-role" src={Images.SWAP_ICON} alt="" />
                    }

                    {
                      (!_.isEmpty(team_info) && team_info.captain_booster_status.applied_week == SelectedWeek && item.player_role == 1) &&
                      <img className="booster-icn" src={Images.CAP_BOOSTER} alt="" />
                    }
                  </Fragment>
                }
              </Fragment>
            }
            {
              isDefault ?
                <div className="player-item-caption default">
                  {
                    is_single_player &&
                    <><Trans>Rank</Trans>{" "}</>
                  }
                  {item.position_label}
                </div>
                :
                <div className={`player-item-caption  ${item.is_bench_player == 1 ? 'bench-caption' : ''} ${this.props.benchClass}`} onClick={isDefault || item.highlight || item.opacity ? null : () => PlayerCardInit(item)}>
                  {/* <div className="lg">{item.nick_name}</div> */}
                  <div className="lg"> <span>{item.full_name}</span> </div>


                  {/* {
                  is_single_player ? 
                  <>

                  </>
                  :
                  <>

                  </>
                }
                {
                  selectedPCF == 1 &&
                  <div className="small">{selectedPCF}</div>
                } */}

                  {
                    is_single_player ?
                      <>
                        {
                          joined_week < next_week || next_week == last_week || IS_POINT ?
                            <div className="small"><Trans>Rank</Trans> {item.rank} | {item.score} <Trans>Pts</Trans></div>
                            :
                            <div className="small"><Trans>Rank</Trans> {item.rank}</div>
                        }
                      </>
                      :
                      <>
                        {/* {console.log('joined_week ===> ', joined_week, '| next_week ===> ', next_week, '| last_week ===> ', last_week)} */}
                        {
                          joined_week && (joined_week < next_week || next_week == last_week || IS_POINT) ?
                            <div className="small">{item.score} <Trans>Pts</Trans></div>
                            :
                            //<div className="small">${item.salary} | {item.team_abbreviation}</div>
                            <div className="small">{AppMasterData.CURRENCY_CODE} {' '}{item.salary} {REACT_APP_SALARY_SUBFFIX}</div>
                          // <div className="small">${item.salary} | {item.team_abbreviation == item.home ? 'vs ' : '@ '}{item.against_team}</div>
                        }
                      </>
                  }
                  {/* {
                    item.is_eliminate == 1 &&
                    <div className="order red">
                      <Trans>Eliminated</Trans>
                    </div>
                  } */}
                  {
                    item.player_other_status == "1" && 
                    <div className="order gray">
                      <Trans>Suspended</Trans>
                    </div>
                  }
                   {
                    item.player_other_status == "2" && 
                    <div className="order red">
                      <Trans>Eliminated</Trans>
                    </div>
                  }
                  {
                    item.player_other_status == "3" && 
                    <div className="order yellow">
                      <Trans>Injured</Trans>
                    </div>
                  }

                  
                  {
                    item.player_other_status == "5" && 
                    <div className="order red">
                      <Trans>Unregistered</Trans>
                    </div>
                  }
                 
                  {/* {
                  item.is_bench_player == 1 &&
                  <div className="order yellow">
                    {
                      item.position == constant_pos.P0 ? 
                      item.position
                      :
                      `${(_.cloneDeep(item.bench_order))}. ${item.position}`
                    }
                  </div>
                } */}
                </div>
            }
          </div>
        </div >
        {/* {item.position == 'GK' && <div>Goal Keeper</div>}
        {item.position == 'DF' && <div>Defender</div>}
        {item.position == 'MF' && <div>Mid Filder</div>}
        {item.position == 'FW' && <div>Forward</div>} */}
      </>
    )
  }
}
FieldPlayer.defaultProps = {
  isDefault: true,
  RemovePlayer: () => { },
  item: {},
  LineupStage: Constant.LINEUP_STAGE_CREATE,
  PlayerListNode: () => { },

  SwapInit: () => { },
  SwapExecute: () => { },
}
export default FieldPlayer