import { Component } from 'react';
import { _, API, Http, Utils, Trans, Images } from 'Helpers';
import { Col, Container, Row } from 'reactstrap';
import { Banner, MultiBanner } from 'Components';
class Prizes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedLeague: Utils.getMultiLeg(),
      // miniLeagueData:''
      HomeData: [],
      isLoader: false
    }

  }


  getPrizes = () => {
    this.setState({ isLoader: true })
    const { league_id, sports_id } = this.state.SelectedLeague;
    let param = {
      "sports_id": sports_id,
      "league_id": league_id,
    }
    // this.setState({ isLoaderShow: true })
    Http.post(API.GET_PRIZES, param).then(res => {
      const { data } = res;
      // this.setState({ isLoaderShow: false })
      // this.GetTopScoringPlayers();
      if (res.response_code === 200) {
        this.setState({
          HomeData: data,
          isLoader: false
        })
      }

      // if (data.winner_prizes.length > 2) {
      //     this.setState({
      //         // firstPrizeDetail: data.winner_prizes[0],
      //         // secondPrizeDetail: data.winner_prizes[1],
      //         // thirdPrizeDetail: data.winner_prizes[2]
      //     })
      // }

    }).catch(error => {
      console.error(error)
    });

  }
  scrollToJoin = () => {
    const element = document.getElementById("prz-update");
    element.scrollIntoView({});
  };
  // prz-update

  componentDidMount() {
    this.getPrizes()

    if (!_.isUndefined(this.props.nw) && this.props.nw) {
      this.scrollToJoin()
    }
  }

  isMobileView = () => {
    return window.innerWidth <= 767;
  }
  render() {
    // const {miniLeagueData} = this.state
    const { HomeData, isLoader } = this.state

    // console.log(HomeData[0].end_week, 'HomeDataHomeData')

    return (
      <Container className='lobby-container prize-wrap p-fix-prz'>
        <div className='multibanner-wrap mb-pd'>
          <Banner type='56' paddingBottom />
          <MultiBanner type='22' paddingBottom />
        </div>
        <Row className='pd-fix'>
          <Col className='text-center'>
            <div className='mini-banner-image'>
              {/* <img src={miniLeagueData.web_banner_image_url} alt="" /> */}
            </div>

          </Col>
        </Row>
        {isLoader ?
          <div className="spinner-static">
            <Utils.Spinner primary />
          </div>
          : <>
            {_.isEmpty(HomeData) ?
              <Row>
                <Col>
                  <div className='prizes-box'>
                    <h5 className='no-data-prize'><Trans>Prices not added by admin yet</Trans></h5>
                  </div>
                </Col>
              </Row>
              :
              <Row className='pd-fix'>
                <Col>
                  <div className='prize-main-wrapper'>
                    {_.map(HomeData, (itm, idx) => {
                      return (
                        <div key={idx} id={itm.contest_access_type === '2' && idx !== 1 ? '' : idx === 1 ? 'prz-update' : ''}>
                          <div className='bold-cls'>
                            <span>
                              {itm.contest_name}
                            </span>
                            <div className='bottom-gw-disc'><Trans>{Utils.getWeekLabel(Utils.getMultiLeg().week_label['1'])} {itm.start_week} - {itm.end_week}</Trans></div>
                          </div>
                          {itm.contest_access_type === '2' &&
                            <div className='banner-img-block'>
                              {/* <img className='img-width' src={this.isMobileView() ? itm.app_banner_image : itm.web_banner_image} alt='league-banner' /> */}
                              <Banner type={this.isMobileView() ? 64 : 63} />
                              <div className='small-container m-fix'>
                                <div className='prz-wrap'>
                                  <span className='min-max-prz'><Trans>Rank</Trans></span>
                                  <span className='prz-name mr-10'><Trans>Prizes</Trans></span>
                                </div>
                              </div>
                            </div>
                          }
                          <div className='prize-ct-wrap small-container'>
                            {!_.isEmpty(itm.prize_distibution_detail) &&
                              <div className='prize-distribution-main'>
                                {_.map(itm.prize_distibution_detail
                                  , (item, index) => {
                                    
                                    return (
                                      <div className='prz-wrap' key={index}>
                                        <span className='mw-32p'>
                                          {(item.min === '1' || item.min === '2' || item.min === '3') && < img src={Images.LEFT_LEAFS} alt='' className='img-revert' />}
                                          <span className={(item.min === '1' || item.min === '2' || item.min === '3') ? 'xbold-txt' : 'min-max-prz'}>
                                            {item.min === item.max ? item.min : item.min + ' - ' + item.max}
                                          </span>
                                          {(item.min === '1' || item.min === '2' || item.min === '3') && < img src={Images.RIGHT_LEAFS} alt='' className='img-revert' />}
                                        </span>
                                        <span className={(item.min === '1' || item.min === '2' || item.min === '3') ? 'xxbold-txt prz-name' : 'prz-name'}><Trans>{item.name}</Trans></span>
                                      </div>
                                    )
                                  })}
                              </div>
                            }
                            {!_.isEmpty(itm.weekly_prizes) &&
                              <div className='weekly-wrap'>
                                {
                                  _.map(itm.weekly_prizes, (item, index) => {
                                    return (
                                      <div className='week-names' key={index}>
                                        <div className='weeks'>
                                          <span className='week-title bold-cls'>
                                            <span>
                                              <Trans>{Utils.getWeekLabel(Utils.getMultiLeg().week_label['1'])} {item.week} Prizes</Trans>
                                            </span>
                                          </span>
                                          <div className='week-subtitle bottom-gw-disc'>{Utils.getWeekLabel(Utils.getMultiLeg().week_label['1'])} {item.week}</div>
                                        </div>

                                        {!_.isEmpty(item) &&
                                          _.map(item, (weeks, idx) => {
                                            return (
                                              <div className='week-prz' key={idx}>
                                                {!_.isEmpty(weeks) &&
                                                  _.map(weeks, (itm, key) => {
                                                    return (
                                                      <div className='prz-wrap' key={key}>
                                                        <span className='mw-32p'>
                                                          {(itm.min === '1' || itm.min === '2' || itm.min === '3') && < img src={Images.LEFT_LEAFS} alt='' className='img-revert' />}
                                                          <span className={itm.min === '1' || itm.min === '2' || itm.min === '3' ? 'xbold-txt' : 'min-max-prz'}>
                                                            {itm.min === itm.max ? itm.min : itm.min + ' - ' + itm.max}
                                                          </span>
                                                          {(itm.min === '1' || itm.min === '2' || itm.min === '3') && < img src={Images.RIGHT_LEAFS} alt='' className='img-revert' />}
                                                        </span>
                                                        <span className={(itm.min === '1' || itm.min === '2' || itm.min === '3') ? 'xxbold-txt prz-name' : 'prz-name'}><Trans>{itm.name}</Trans></span>
                                                      </div>
                                                    )
                                                  })}

                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            }
                            {
                              (itm.contest_access_type === '2' && !_.isEmpty(itm.monthly_prizes)) &&
                              <div className='weekly-wrap'>
                                 {
                                  _.map(itm.monthly_prizes, (item, index) => {
                                    return (
                                      <div className='week-names' key={index}>
                                        <div className='weeks'>
                                          <span className='week-title bold-cls'>
                                            <span>
                                              <Trans> {item.label} Prizes</Trans>
                                            </span>
                                          </span>
                                        </div>

                                        {
                                          <div className='week-prz'>
                                                {!_.isEmpty(item) &&
                                                  _.map(item.prize, (itm, key) => {
                                                    return (
                                                      <div className='prz-wrap' key={key}>
                                                        <span className='mw-32p'>
                                                          {(itm.min === '1' || itm.min === '2' || itm.min === '3') && < img src={Images.LEFT_LEAFS} alt='' className='img-revert' />}
                                                          <span className={itm.min === '1' || itm.min === '2' || itm.min === '3' ? 'xbold-txt' : 'min-max-prz'}>
                                                            {itm.min === itm.max ? itm.min : itm.min + ' - ' + itm.max}
                                                          </span>
                                                          {(itm.min === '1' || itm.min === '2' || itm.min === '3') && < img src={Images.RIGHT_LEAFS} alt='' className='img-revert' />}
                                                        </span>
                                                        <span className={(itm.min === '1' || itm.min === '2' || itm.min === '3') ? 'xxbold-txt prz-name' : 'prz-name'}><Trans>{itm.name}</Trans></span>
                                                      </div>
                                                    )
                                                  })}

                                          </div>
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            }
                          </div>

                        </div>
                      )
                    })}
                  </div>
                </Col>
              </Row>
            }
          </>}
      </Container>
    )
  }
}

export default Prizes;
