/* eslint-disable */
import { Component, Fragment } from 'react';
import exif from 'exif-js';
import _ from "lodash";
import moment from 'moment'
import Moment from 'react-moment';
import 'moment-timezone';
import { notify } from 'react-notify-toast';
import { Http, API, Constant, Validation, Trans } from 'Helpers';
import { components } from 'react-select';
import { Modal, Button } from 'reactstrap';
import NavigationPrompt from 'react-router-navigation-prompt';
import LanguageDetect from 'languagedetect';
import Config from 'Helpers/Config';
import Cookies from 'js-cookie'
import { getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";
const lngDetector = new LanguageDetect();
const Images = require.context('../../Assets/img', true);

let flex = 'FLEX'

let _this = null;
class NavigationPromptWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      when: false
    }
  }

  static Init(when) {
    _this.setState({
      when
    })
  }
  render() {
    _this = this;
    const { when } = this.state;
    return process.env.NODE_ENV == "development" ? '' :
      (
        <NavigationPrompt
          renderIfNotActive={true}
          when={when}>
          {({ isActive, onCancel, onConfirm }) => {
            if (isActive) {
              return (
                <Modal isOpen={true} className='alert-dialog' size={'sm'}>
                  <div className='title'><Trans>Alert!</Trans></div>
                  <div><Trans>This will reset your team, are you sure you want to proceed?</Trans></div>
                  <div className="alert-dialog-footer">
                    <Button color="success" onClick={onCancel}><Trans>Cancel</Trans></Button>
                    <Button color="secondary" onClick={onConfirm}><Trans>Continue</Trans></Button>
                  </div>
                </Modal>
              );
            }
            return <div />
          }}
        </NavigationPrompt>
      )
  }
}

// OnboardModal Init

class Spinner extends Component {
  render() {
    return (
      <svg className={`spinner ${this.props.primary ? 'primary' : ''} ${this.props.black ? 'black' : ''} ${this.props.classname ? this.props.classname : ''}`} viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="3"></circle>
      </svg>
    )
  }
}

class FlexboxLoader extends Component {
  render() {
    const { style, init, className } = this.props;
    return (
      <div className={`flexbox-loader-wrapper ${className ? className : ''} ${init ? 'show' : ''}`} style={style}>
        <div className="flexbox-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }
}

class VSCOLoader extends Component {
  render() {
    return (
      <ul className='vsco-loader'>
        <li id='a'></li>
        <li id='b'></li>
        <li id='c'></li>
        <li id='d'></li>
        <li id='e'></li>
        <li id='f'></li>
        <li id='g'></li>
        <li id='h'></li>
        <li id='i'></li>
      </ul>
    )
  }
}

class TriangleLoader extends Component {
  render() {
    return (
      <Fragment>
        <div className="loader-field">
          <svg viewBox="0 0 80 80">
            <circle id="test" cx="40" cy="40" r="32"></circle>
          </svg>
        </div>
        <div className="loader-field triangle">
          <svg viewBox="0 0 86 80">
            <polygon points="43 8 79 72 7 72"></polygon>
          </svg>
        </div>
        <div className="loader-field">
          <svg viewBox="0 0 80 80">
            <rect x="8" y="8" width="64" height="64"></rect>
          </svg>
        </div>
      </Fragment>
    )
  }
}

const Utils = {
  NavigationPrompt: (when) => {
    NavigationPromptWrap.Init(when)
  },

  ordinal_suffix_of: (i) => {
    if (typeof foo !== "number") {
      i = Number(i)
    }
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  },

  getDateYearsOld: () => {
    let date = new Date()
    let year = date.getFullYear() - Constant.USER_AGE_LIMIT
    date.setFullYear(year)
    return date;
  },

  isValid: (name, state) => {
    if (state == '' || state == null) return false;
    return !Validation.init(name, state)
  },
  isNativeWeb:()=>{
    return window.webkit?2:window.mobileApp?1:0
  },
  isInvalid: (name, state) => {
    return Validation.init(name, state)
  },

  isFormValid: (forms, state) => {
    let arr = []
    _.map(forms, (item) => {
      let itemValidate = Validation.init(item, state[item]) === false;
      if (itemValidate) {
        arr.push(itemValidate);
      }
    })
    return arr.length === forms.length;
  },

  setExistingUser: (data) => {
    if(!data) return;
    localStorage.setItem('existing_user', JSON.stringify(data));
  },

  getExistingUser: () => {
    const existing_user = localStorage.getItem('existing_user');
    if (!existing_user) {
      return null;
    }
    return JSON.parse(existing_user);
  },

  setMultiLeg: (data) => {
    localStorage.setItem('is_single_player', JSON.stringify(data.sports_config.is_single_player));
    localStorage.setItem('legData', JSON.stringify(data));
  },

  getMultiLeg: () => {
    const legData = localStorage.getItem('legData');
    if (!legData) {
      return null;
    }
    return JSON.parse(legData);
  },

  removeMultiLeg: () => {
    localStorage.removeItem('legData');
    localStorage.removeItem('is_single_player');
  },

  setSinglePlayerFlag: (is_single_player) => {
    localStorage.setItem('is_single_player', JSON.stringify(is_single_player));
  },
  getSinglePlayerFlag: () => {
    const is_single_player = localStorage.getItem('is_single_player');
    if (!is_single_player) {
      return null;
    }
    return JSON.parse(is_single_player);
  },

  isSinglePlayer: () => {
    const is_single_player = localStorage.getItem('is_single_player');
    if (!is_single_player) {
      return null;
    }
    return (JSON.parse(is_single_player) == 1 ? true : false);
  },

  setProfile: (data) => {
    if (getCookieConsentValue()) {
      Utils.setCookie('_un', JSON.stringify(data.user_name), 365)
      Utils.setCookie('_uid', JSON.stringify(data.user_unique_id), 365)
    }
    localStorage.setItem('profile', JSON.stringify(data));
  },

  setProfileModal: (data) => {
    if (getCookieConsentValue()) {
      Utils.setCookie('profileinfo', data, 365)
    } else {
      localStorage.setItem('profileinfo', JSON.stringify(data));
    }
  },

  getProfileModal: () => {
    const profileinfo = getCookieConsentValue() ? (Utils.getCookie('profileinfo') || localStorage.getItem('profileinfo')) : localStorage.getItem('profileinfo');
    if (!profileinfo) {
      return null;
    }
    if (localStorage.getItem('profileinfo') && getCookieConsentValue()) {
      localStorage.removeItem('profileinfo');
      Utils.setCookie('profileinfo', true, 365)
    }
    return JSON.parse(profileinfo);
  },

  getProfile: () => {
    const profile = localStorage.getItem('profile');
    if (!profile) {
      return null;
    }
    return JSON.parse(profile);
  },

  removeProfile: () => {
    Utils.removeCookie('_un')
    Utils.removeCookie('_uid')
    localStorage.removeItem('profile');
  },

  // LINEUP
  setCurrentLineup: (current_lineup) => {
    localStorage.setItem(`current_lineup`, JSON.stringify(current_lineup));
  },

  getCurrentLineup: () => {
    let obj = {
      week_info: {},
      team_name: '',
      team_info: {}
    }
    const current_lineup = localStorage.getItem(`current_lineup`);
    if (!current_lineup) {
      return obj;
    }
    let data = JSON.parse(current_lineup || {})
    if (data.user_join_count == 0) {
      return {
        ...data, ...obj
      }
    } else {
      return JSON.parse(current_lineup);
    }
  },

  removeCurrentLineup: () => {
    localStorage.removeItem(`current_lineup`);
  },

  setLineup: (lineup) => {
    localStorage.setItem(`lineup`, JSON.stringify(lineup));
  },

  getLineup: () => {
    const lineup = localStorage.getItem(`lineup`);
    if (!lineup) {
      return null;
    }
    return JSON.parse(lineup);
  },

  setJoined: (joined) => {
    localStorage.setItem(`joined`, JSON.stringify(joined));
  },

  getJoined: () => {
    const joined = localStorage.getItem(`joined`);
    if (!joined) {
      return null;
    }
    return JSON.parse(joined);
  },
  setNext: (joined) => {
    localStorage.setItem(`next`, JSON.stringify(next));
  },

  setNext: () => {
    const next = localStorage.getItem(`next`);
    if (!next) {
      return null;
    }
    return JSON.parse(next);
  },
  setActive: (active) => {
    localStorage.setItem(`active`, JSON.stringify(active));
  },

  getActive: () => {
    const active = localStorage.getItem(`active`);
    if (!active) {
      return null;
    }
    return JSON.parse(active);
  },

  removeLineup: () => {
    localStorage.removeItem(`lineup`);
  },

  check_position: (num, arr) => {
    let status = false
    let _num = Number(num)
    let A = Number(arr[0])
    let B = Number(arr[arr.length - 1])
    for (let i = A; i <= B; i++) {
      if (_num == i) {
        status = true
        break;
      }
    }
    return status
  },

  DefaultPlayer: (item) => {
    let PlayerObj = {
      position: item.position,
      position_clone: item.position_clone || item.position,
      position_label: item.position_label || item.position,
      is_bench_player: item.is_bench_player || 0,
      bench_order: item.bench_order || 0,
      player_role: 0,
      player_uid: null,
      salary: 0,
    }
    return PlayerObj
  },

  IsPlayerExist: (obj, list) => {
    var x;
    for (x in list) {
      if (list[x].player_uid == obj.player_uid) {
        return true;
      }
    }
    return false;
  },

  IsPlayerField: (obj, list) => {
    var x;
    for (x in list) {
      if (list[x].player_uid == obj.player_uid
        // && list[x].is_bench_player == obj.is_bench_player && obj.is_bench_player == 0
      ) {
        return true;
      }
    }
    return false;
  },

  AvailableSalary: (SalaryCap, LineupList) => {
    let salary = _.sumBy(LineupList, function (o) {
      return Number(o.salary)
      //parseFloat(Number(o.salary))
    })
    //console.log(SalaryCap-salary)
    return (SalaryCap - salary).toFixed(2) || 0;
  },

  playerNameSort: (name) => {
    if (!name) return
    var str = name.split(' ');
    str[0] = str[0].charAt(0)
    return str[0] + '. ' + str[str.length - 1]
  },

  SelectPlayerCount: (data) => {
    if (_.isEmpty(data)) return 0;
    let result = _.filter(data, (o) => { return o.player_uid != undefined })
    return result.length;
  },

  GetSelectPlayer: (data) => {
    if (_.isEmpty(data)) return 0;
    let result = _.filter(data, (o) => { return o.player_uid != undefined })
    return result;
  },

  SelectedPositionCount: (data, val, is_single_player = false, all_position) => {
    if (is_single_player) {
      Utils.PlayerPositionCheck(val.position, all_position)
      let result = _.filter(data, (o) => {
        return val.position != '' ? (
          (is_single_player ? (Utils.PlayerPositionCheck(val.position, all_position) == o.position.join('to')) : (o.position == val.position)) && o.player_uid
        ) : o.player_uid != null
      })
      return result.length;
    } else {
      let result = _.filter(data, (o) => { return val.position != '' ? (o.position == val.position && o.player_uid) : o.player_uid != null })
      return result.length;
    }
  },

  PlayerPositionCheck: (rank, all_position) => {
    let prev_obj = { margin: -1, position: '' }
    for (let obj of all_position) {
      if (obj.position_name && (obj.position_name.indexOf('to') >= 0)) {
        const _pos = obj.position_name.split('to')
        const _diff = parseInt(_pos[1]) - parseInt(rank)
        if (_diff >= 0 && (prev_obj.margin > _diff || prev_obj.margin == -1)) {
          prev_obj.margin = _diff
          prev_obj.position = obj.position_name
        }
      }
    }
    return prev_obj.position
  },

  AddPlayerToLineup: (option) => {

    let { player, lineup, salary, max_player_per_team, all_position, lineup_roster_limit, next_week } = option;
    const is_single_player = Utils.isSinglePlayer()

    const isMaxPlayerPerTeam = () => {
      if (is_single_player) return false;
      let filterArr = _.filter(lineup, (o) => {
        return o.team_league_id == player.team_league_id
      })
      return !(filterArr.length < Number(max_player_per_team));
    }

    const ExecuteLineup = () => {
      let i = 0;
      lineup = _.sortBy(lineup, 'is_bench_player')
      while (i < lineup.length) {

        if (is_single_player) {
          if (Utils.check_position(player.position, lineup[i].position) && !lineup[i].player_uid) {
            lineup[i] = {
              ...player,
              position: Utils.PlayerPositionCheck(player.position, all_position).split('to'),
              is_bench_player: lineup[i].is_bench_player,
              bench_order: lineup[i].bench_order,
              player_role: lineup[i].player_role,
              position_label: lineup[i].position_label,
              position_clone: lineup[i].position_clone,
            };
            break;
          }
        } else {
          if (lineup[i].position === player.position && !lineup[i].player_uid) {
            lineup[i] = {
              ...player,
              is_bench_player: lineup[i].is_bench_player,
              bench_order: lineup[i].bench_order,
              player_role: lineup[i].player_role,
            };
            break;
          }
        }
        i++;
      }
      return lineup;
    }

    let Position = _.filter(all_position, (o) => {
      if (is_single_player) {
        if (o.position != '') {
          return Utils.PlayerPositionCheck(player.position, all_position) == o.position_name;
        }
      }
      return o.position == player.position
    })

    function _dynamicMsz(str, num) {
      return str.replace('{{num}}', num)
    }

    return new Promise((resolve, reject) => {
      switch (true) {

        case Utils.SelectPlayerCount(lineup) === lineup.length:
          reject(_dynamicMsz(Constant.MSZ_LINEUP_FULL, lineup_roster_limit))
          break;

        case salary < parseInt(player.salary, 10):
          reject(Constant.MSZ_BUDGET_INSUFFICIENT)
          break;

        case Position.length == 0:
          reject((player.position_label || player.position) + ' ' + Constant.MSZ_POSITION_NOT_SELECTED)
          break;

        case (is_single_player ? Utils.SelectedPositionCount(lineup, player, is_single_player, all_position) === ((is_single_player && next_week == 2 && Constant.WEEK_JUMPED) ? Number(Position[0].max_player_per_position) : Number(Position[0].number_of_players)) : Utils.SelectedPositionCount(lineup, player) === Number(Position[0].max_player_per_position)):
          // case Utils.SelectedPositionCount(lineup, player, is_single_player) === (next_week == 2 ? Number(Position[1].max_player_per_position) : Number(Position[0].max_player_per_position)):
          reject(`You can add only ${Position[0].max_player_per_position} ${Position[0].max_player_per_position == 1 ? 'player' : 'players'} from ${Position[0].position_name} ${is_single_player ? 'rank' : ''} in your lineup`)
          // reject(Constant.MSZ_MAX_POSITION_SELECTED)
          break;

        case isMaxPlayerPerTeam():
          reject(_dynamicMsz(Constant.MSZ_MAX_PLAYER_PER_TEAM, max_player_per_team))
          break;

        default:
          resolve(ExecuteLineup())
          break;
      }
    })
  },

  RemovePlayerFromLineup: (option) => {
    let { player, lineup, active_week, next_week, joined_week } = option;
    const is_single_player = Utils.isSinglePlayer()


    const ExecuteLineup = () => {
      let i = 0;
      while (i < lineup.length) {
        if (lineup[i].player_uid === player.player_uid) {
          let _sugPos = (joined_week < next_week && next_week != 1 && Constant.WEEK_JUMPED) ? '1to184' : lineup[i].position_clone
          let _sugPosLbl = (joined_week < next_week && next_week != 1 && Constant.WEEK_JUMPED) ? '1to184' : lineup[i].position_label

          let DefaultPlayer = Utils.DefaultPlayer({
            ...player,
            ...(is_single_player ? {
              position: _.cloneDeep(_sugPos.split('to')),
              position_label: _sugPosLbl,
              position_clone: _sugPos,
            } : null)
          });

          lineup[i] = {
            ...DefaultPlayer,
            is_bench_player: lineup[i].is_bench_player,
            bench_order: lineup[i].bench_order,
            player_role: lineup[i].player_role,
          };

          break;
        }
        i++;
      }
      return lineup;
    }

    return new Promise((resolve, reject) => {
      resolve(ExecuteLineup());
    })
  },

  RandomBenchPlayer: (option) => {
    const { lineup, formation } = option;

    for (let i = 0; i < formation.length; i++) {

      const element = formation[i];
      var filterArr = lineup.filter(function (item) {
        return item.position == element.position
      })
      _.map(filterArr, (item, idx) => {
        item.is_bench_player = "0"
        if (idx < element.number_of_bench_player) {
          item.is_bench_player = "1"
        }
        return item;
      })
    }
    // return lineup
    return new Promise((resolve, reject) => {
      resolve(lineup)
    })
  },

  RandomFlex: (option) => {
    const { lineup } = option;


    let FlexTobeFltr = [];
    let FlexPlayer = {};
    let FLEX = _.filter(lineup, (o) => {
      let tmp = "QB,RB,WR,TE";
      if (tmp.includes(o.position) && o.is_bench_player == 0) {
        FlexTobeFltr.push(o);
      }
      return (o.position === flex) || (o.roster_position === flex)
    })

    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    if (_.isEmpty(FLEX)) {
      let idx = getRandomInt(0, FlexTobeFltr.length - 1);
      FlexTobeFltr[idx].roster_position = flex;
      FlexPlayer = FlexTobeFltr[idx];

      _.map(lineup, (item, idx) => {
        if (item.player_uid === FlexPlayer.player_uid) {
          item = FlexPlayer;
        } else {
          item.roster_position = null;
        }
        return item;
      })
    }
    return lineup;
  },

  LineupOptimize: (lineup) => {
    let LineupList = []
    _.map(lineup, (item) => {
      LineupList.push({
        "player_team_id": item.player_team_id,
        "position": item.roster_position === flex ? item.roster_position : item.position,
        "is_bench_player": item.is_bench_player
      })

      return item;
    })
    return LineupList;
  },

  getUrlParams: (search) => {
    if (search == "") return {};
    let hashes = search.slice(search.indexOf('?') + 1).split('&')
    return hashes.reduce((params, hash) => {
      let [key, val] = hash.split('=')
      return Object.assign(params, { [key]: JSON.parse(decodeURIComponent(val)) })
    }, {})
  },

  omit: () => (obj, arr) => Object.keys(obj).reduce((res, key) => {
    if (arr.indexOf(key) === -1) {
      res[key] = obj[key]
    }
    return res
  }, {}),

  getUtcToLocal: (date, format = '') => {
    let localDate = moment(date).utc(true).local().format(format);
    return localDate;
  },
  getTodayTimestamp: () => {
    let date = new Date()
    let today = moment(date).utc(true).local().valueOf();
    return today;
  },

  setCookie: (cname, cvalue, exdays) => {
    // var d = new Date();
    // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    // var expires = "expires=" + d.toUTCString();
    // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    Cookies.set(cname, cvalue, { expires: 7, path: '' })
  },

  getCookie: (cname) => {
    // var name = cname + "=";
    // var decodedCookie = decodeURIComponent(document.cookie);
    // var ca = decodedCookie.split(';');
    // for (var i = 0; i < ca.length; i++) {
    //   var c = ca[i];
    //   while (c.charAt(0) == ' ') {
    //     c = c.substring(1);
    //   }
    //   if (c.indexOf(name) == 0) {
    //     return c.substring(name.length, c.length);
    //   }
    // }
    // return "";
    return Cookies.get(cname)
  },

  removeCookie: (name) => {
    Cookies.remove(name)
    // document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  },

  numberWithCommas: (x, symbol = '') => {
    if (x == undefined) return x;
    x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return symbol + x || symbol + '0';
  },

  getMasterData: () => {
    try {
      if (_.isUndefined(getCookieConsentValue())) {
        resetCookieConsentValue()
      }
      const isCookieConsentValue = _.isUndefined(getCookieConsentValue()) ? null : getCookieConsentValue();
      const _mdt = isCookieConsentValue ? Utils.getCookie('_mdt') : localStorage.getItem('_mdt');
      if (!_mdt) {
        return null;
      }
      return JSON.parse(_mdt);
    } catch (error) {

    }
  },

  setMasterData: (data) => {
    if (getCookieConsentValue()) {
      localStorage.removeItem('_mdt');
      Utils.setCookie('_mdt', JSON.stringify(data), 1)
    } else {
      localStorage.setItem('_mdt', JSON.stringify(data));
    }
  },

  removeMasterData: () => {
    if (getCookieConsentValue()) {
      Utils.removeCookie('_mdt')
    } else {
      localStorage.removeItem('_mdt');
    }
  },

  DateFormat: (date, format = Constant.DATE_FORMAT) => {
    return (
      <Moment locale='ar' format={format} tz={Constant.TIMEZONE} className='date-time-moment'>{date}</Moment>
    )
  },

  DobFormat: (date) => {
    return moment(date).format('MMM DD, YYYY')
  },

  DobFormatView: (date) => {
    return moment(date).format('MMM DD, YYYY')
  },

  Calculate: (type = '', item = {}) => {
    let total_wager = Number(item.total_wager_amt);
    let total_max_win = _.sumBy(item.bets, function (o) { return (Number(o.wager_amt) * Number(o.payout_ratio)); });

    switch (type) {
      case '1': // Winnings
        let Winnings = _.sumBy(item.bets, function (o) {
          return (o.bet_point <= item.total_score) ? (Number(o.wager_amt) * Number(o.payout_ratio)) : 0;
        });
        return Utils.numberWithCommas(Winnings);

      case '2': // Max Pay
        return Utils.numberWithCommas(total_max_win + total_wager)

      case '3': // Tier Count
        let _arr = []
        _.forEach(item.bets, o => {
          if (o.bet_point <= item.total_score) {
            _arr.push(o)
          }
        })
        return _arr.length;

      case '4':
        return Utils.numberWithCommas(item.wager_amt * item.payout_ratio)

      default:
        break;
    }
  },

  notify: (message = "", type = 'success', duration = 2500, customColor = { background: '#0E1717', text: "#FFFFFF" }) => {
    // type?: 'success' | 'error' | 'warning' | 'custom',
    notify.show(message, type, duration, customColor);
  },

  isDev: () => {
    return process.env.NODE_ENV === 'development'
  },

  mediaQuery: (innerWidth, name) => {
    let max = Constant[name]
    return max > innerWidth
  },

  resizeBind: (fun) => {
    window.addEventListener("resize", fun)
  },

  resizeUnbind: (fun) => {
    window.removeEventListener("resize", fun);
  },

  IsPlayerRoleExist: (role, list) => {
    var x;
    for (x in list) {
      if (list[x].player_role == role) {
        return true;
      }
    }
    return false;
  },

  s3UrlConvert: (param,langBypass=false) => {
    let url = [
      `${Config.HTTP_PROTOCOL}://${Config.BUCKET_NAME}.s3.${Config.BUCKET_REGION}.amazonaws.com/static/`,
      Config.BUCKET_DATA_PREFIX,
      langBypass ? '' :Utils.getLang(),
      ...param
    ]
    url = url.join('_')
    url = url.replace(/_+/g, '_');
    url = url.replace("/_", "/");
    return Config.BUCKET_STATIC_DATA_ALLOWED == 1 ? `${url}.json` : null
  },
  s3UrlCtConvert: (param) => {
    let url = [
      `${Config.HTTP_PROTOCOL}://${Config.BUCKET_NAME}.s3.${Config.BUCKET_REGION}.amazonaws.com/static/`,
      Config.BUCKET_DATA_PREFIX,
      // Utils.getLang(),
      ...param
    ]
    url = url.join('_')
    url = url.replace(/_+/g, '_');
    url = url.replace("/_", "/");
    return Config.BUCKET_STATIC_DATA_ALLOWED == 1 ? `${url}.json` : null
  },
  SetSessionStorage: (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  GetSessionStorage: (key) => {
    const data = sessionStorage.getItem(key);
    if (!data) {
      return null;
    }
    return JSON.parse(data);
  },
  RemoveSessionStorage: (key) => {
    sessionStorage.removeItem(key);
  },

  DropdownIndicator: (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="icon-down" />
      </components.DropdownIndicator>
    )
  },
  readFile: (file) => {
    return new Promise(resolve => {
      var reader = new FileReader();
      reader.onload = e => resolve(e.target.result);
      reader.readAsDataURL(file);
    });
  },
  createImage: (data) => {
    return new Promise(resolve => {
      const img = document.createElement('img');
      img.onload = () => resolve(img);
      img.src = data;
    })
  },

  rotate: (type, img) => {
    return new Promise(resolve => {
      const canvas = document.createElement('canvas');

      exif.getData(img, function () {
        var orientation = exif.getAllTags(this).Orientation;

        if ([5, 6, 7, 8].indexOf(orientation) > -1) {
          canvas.width = img.height;
          canvas.height = img.width;
        } else {
          canvas.width = img.width;
          canvas.height = img.height;
        }

        var ctx = canvas.getContext("2d");

        switch (orientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, img.width, 0);
            break;
          case 3:
            ctx.transform(-1, 0, 0, -1, img.width, img.height);
            break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, img.height);
            break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            ctx.transform(0, 1, -1, 0, img.height, 0);
            break;
          case 7:
            ctx.transform(0, -1, -1, 0, img.height, img.width);
            break;
          case 8:
            ctx.transform(0, -1, 1, 0, 0, img.width);
            break;
          default:
            ctx.transform(1, 0, 0, 1, 0, 0);
        }

        ctx.drawImage(img, 0, 0, img.width, img.height);
        ctx.toBlob(resolve, type);
      });
    })
  },

  ClearBadge: () => {
    Http.get(API.UPDATE_READ_ALL).then(res => {
      // DefaultHeader.notificationUpdate(0)
    })
  },

  getLangPosting: () => {
    const lang_posting = localStorage.getItem('lang_posting');
    if (!lang_posting) {
      return null;
    }
    return JSON.parse(lang_posting);
  },

  setDefaultFormation: (data) => {
    localStorage.setItem('default_formation', JSON.stringify(data));
  },

  getDefaultFormation: () => {
    const default_formation = localStorage.getItem('default_formation');
    if (!default_formation || default_formation == 'undefined') {
      localStorage.removeItem('default_formation');
      return null;
    }
    return JSON.parse(default_formation);
  },

  removeDefaultFormation: () => {
    localStorage.removeItem('default_formation');
  },

  setGuestTeam: (data) => {
    localStorage.setItem('guest_team', JSON.stringify(data));
  },

  getGuestTeam: (data) => {
    const guest_team = localStorage.getItem('guest_team');
    if (!guest_team) {
      return null;
    }
    return JSON.parse(guest_team);
  },

  removeGuestTeam: () => {
    localStorage.removeItem('guest_team');
  },

  setPrevLineup: (data) => {
    localStorage.setItem('prev_lineup_copy', JSON.stringify(data));
  },
  getPrevLineup: () => {
    const prev_lineup_copy = localStorage.getItem('prev_lineup_copy');
    if (!prev_lineup_copy) {
      return null;
    }
    return JSON.parse(prev_lineup_copy);
  },
  removePrevLineup: () => {
    localStorage.removeItem('prev_lineup_copy');
  },
  lineupStorageClean: () => {
    localStorage.removeItem(`lineup`);
    localStorage.removeItem('default_formation');
    localStorage.removeItem('prev_lineup_copy');
    localStorage.removeItem('guest_team');
  },

  langDetect: (str) => {
    let res = lngDetector.detect(str, 1)
    let lang = !_.isEmpty(res) ? res[0][0] : 'arabic'
    return lang == 'arabic' ? 'direction-rtl' : 'direction-ltr'
  },
  isDateTimePast: (season_scheduled_date) => {
    let date = moment(season_scheduled_date).utc(true).local().valueOf()
    let now = moment().utc().local().valueOf();
    return now > date;
  },
  dateIsBetween: (start_date, end_date) => {
    const _now = new Date()
    const compareDate = moment(Utils.getUtcToLocal(_now)).format("X")
    const startDate = moment(Utils.getUtcToLocal(start_date)).format("X")
    const endDate = moment(Utils.getUtcToLocal(end_date)).format("X")
    return (startDate <= compareDate && compareDate <= endDate)
  },
  setLineupMasterId: (item = Utils.getMultiLeg()) => {
    let param = {
      "sports_id": item.sports_id,
      "league_id": item.league_id,
    }
    return new Promise((resolve, reject) => {
      Http.post(API.MY_CURRENT_LINEUP, param).then((responseJson) => {
        Utils.setCurrentLineup(responseJson.data)
        resolve(true)
      }).catch(err => {
        reject(false)
        console.log(err);
      })
    })
  },
  compareObjects: (o, p) => {
    var i,
      keysO = Object.keys(o).sort(),
      keysP = Object.keys(p).sort();
    if (keysO.length !== keysP.length)
      return false;//not the same nr of keys
    if (keysO.join('') !== keysP.join(''))
      return false;//different keys
    for (i = 0; i < keysO.length; ++i) {
      if (o[keysO[i]] instanceof Array) {
        if (!(p[keysO[i]] instanceof Array))
          return false;
        //if (compareObjects(o[keysO[i]], p[keysO[i]] === false) return false
        //would work, too, and perhaps is a better fit, still, this is easy, too
        if (p[keysO[i]].sort().join('') !== o[keysO[i]].sort().join(''))
          return false;
      }
      else if (o[keysO[i]] instanceof Date) {
        if (!(p[keysO[i]] instanceof Date))
          return false;
        if (('' + o[keysO[i]]) !== ('' + p[keysO[i]]))
          return false;
      }
      else if (o[keysO[i]] instanceof Function) {
        if (!(p[keysO[i]] instanceof Function))
          return false;
        //ignore functions, or check them regardless?
      }
      else if (o[keysO[i]] instanceof Object) {
        if (!(p[keysO[i]] instanceof Object))
          return false;
        if (o[keysO[i]] === o) {//self reference?
          if (p[keysO[i]] !== p)
            return false;
        }
        else if (Utils.compareObjects(o[keysO[i]], p[keysO[i]]) === false)
          return false;//WARNING: does not deal with circular refs other than ^^
      }
      if (o[keysO[i]] !== p[keysO[i]])//change !== to != for loose comparison
        return false;//not the same value
    }
    return true;
  },

  logoutClear: () => {
    Utils.removeCookie('_un')
    Utils.removeCookie('_uid')
    localStorage.removeItem('profile');
    localStorage.removeItem(`current_lineup`);
    localStorage.removeItem(`lineup`);
    localStorage.removeItem(`auth`);
  },
  reloadLocal: () => {
    Utils.removeCookie('_un')
    Utils.removeCookie('_uid')
    localStorage.removeItem('constant_pos');
    localStorage.removeItem(`current_lineup`);
    localStorage.removeItem(`lineup`);
  },

  getTransferData: () => {
    const _tr = localStorage.getItem('_tr');
    if (!_tr) {
      return {
        in: [],
        out: []
      };
    }
    return JSON.parse(_tr);
  },
  setTransferData: (data, type) => {
    let _d = Utils.getTransferData() || {
      in: [],
      out: []
    }

    if (type == 0) {
      if (Utils.IsPlayerExist(data, _d.in)) {
        _d.in = _.filter(_d.in, o => o.player_uid != data.player_uid)
      } else {
        _d.out = [..._d.out, data]
      }
    } else {
      if (Utils.IsPlayerExist(data, _d.out)) {
        _d.out = _.filter(_d.out, o => o.player_uid != data.player_uid)
      } else {
        _d.in = [..._d.in, data]
      }
    }

    localStorage.setItem('_tr', JSON.stringify(_d));
  },

  removeTransferData: () => {
    localStorage.removeItem('_tr');
  },

  isCapAvailable: (LineupList) => {
    if (_.isEmpty(LineupList)) return false
    return _.filter(LineupList, o => o.player_role == 1).length > 0
  },

  setLang: (lang) => {
    localStorage.setItem('lang', JSON.stringify(lang));
  },
  getLang: () => {
    const lang = localStorage.getItem('lang');
    if (!lang) {
      return null;
    }
    return JSON.parse(lang);
  },
  removeLang: () => {
    localStorage.removeItem('lang');
  },

  getS3URL: (file) => {
    if (!Config) return false
    const s3url = `${Config.HTTP_PROTOCOL}://${Config.BUCKET_NAME}.s3.${Config.BUCKET_REGION}.amazonaws.com/assets/img/`
    return (file || '').includes('http') ? file : s3url + file;
  },

  getImageBaseUrl: (img) => {
    let IMAGE_BASE_URL = Utils.getS3URL(img);
    return (process.env.NODE_ENV === 'production' && Config.BUCKET_STATIC_DATA_ALLOWED == 1) ? IMAGE_BASE_URL : Images(`./${img}`).default;
  },

  setFormationInitial: (data) => {
    localStorage.setItem('constant_pos', JSON.stringify(data))
  },
  getFormationInitial: () => {
    const constant_pos = localStorage.getItem('constant_pos');
    if (!constant_pos) {
      return null;
    }
    return JSON.parse(constant_pos);
  },
  removeFormationInitial: () => {
    localStorage.removeItem('constant_pos');
  },
  setAppInfo: (name, value) => {
    localStorage.setItem(name, JSON.stringify(value))
  },
  getAppInfo: (name) => {
    const _val = localStorage.getItem(name);
    if (!_val) {
      return null;
    }
    return JSON.parse(_val);
  },
  removeAppInfo: (name) => {
    localStorage.removeItem(name);
  },
  dataSorting: (data = [], key = '', order = 'desc') => {  // or 'desc'
    return _.orderBy(data, [(o) => { return Number(o[key]) || 0 }], [order])
  },
  RosterFilterInit: ({
    is_trasfer = false,
    LineupList = [],
    data = [],
    selected_position = {},
    selected_sorting = {},
    selected_team = {},
    player_search_str = '',
    PriceRange = [0, 100]
  }) => {
    //console.log(data,"datadata");
    
    let _fltrData = _.filter(data, item => {
      // console.log(item,"itemitemitemitemitemitem");
      return (_.isEmpty(selected_position) ? true : item.position == selected_position.position || selected_position.position == '')
        && (_.isEmpty(selected_team) ? true : item.team_id == selected_team.team_id || selected_team.team_id == '')
        && (player_search_str == '' || _.includes(_.lowerCase(item.full_name), _.lowerCase(player_search_str)))
        && (Number(item.salary) >= PriceRange[0] && Number(item.salary <= PriceRange[1]))
        && !(is_trasfer && Utils.IsPlayerExist(item, LineupList))
    });
    // console.log(_fltrData.length, '<<<< Count');
    //  console.log(selected_sorting.value);
    
    return Utils.dataSorting(_fltrData, selected_sorting.value)
    // return _.orderBy(_fltrData, [selected_sorting.value], ['asc'])
  },

  setCpSession: (src = null) => {
    if (window.ReactNativeWebView) return null
    if (src) {
      window.sessionStorage.setItem("_utm", encodeURIComponent(src));
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const utm_source = urlParams.get('utm_source');
      let getcp = Utils.getCpSessionPath()
      if (getcp.includes(utm_source)) return null
      if (utm_source) {
        window.sessionStorage.setItem("_utm", encodeURIComponent(window.location.search));
      }
    }
  },

  getCpSession: () => {
    if (window.ReactNativeWebView) return null
    const urlParams = new URLSearchParams(decodeURIComponent(window.sessionStorage.getItem("_utm")));
    return {
      source: urlParams.get('utm_source') || '',
      medium: urlParams.get('utm_medium') || '',
      campaign: urlParams.get('utm_campaign') || '',
      term: urlParams.get('utm_term') || '',
      content: urlParams.get('utm_content') || '',
    }
  },
  getCpSessionPath: () => {
    if (window.ReactNativeWebView) return null
    return window.sessionStorage.getItem("_utm") ? decodeURIComponent(window.sessionStorage.getItem("_utm")) : ''
  },
  bindMessage: (str = '', key = '', value = '') => {
    return _.replace(str, key, value);
  },
  getWeekLabel: (week) => {
    if (!week) return '';
    return week.replace(/[0-9]/g, '');
  },
  isVCAvailable: (LineupList) => {
    if (_.isEmpty(LineupList)) return false
    return _.filter(LineupList, o => o.player_role == 2).length > 0
  },
}
const exDefaujlt = { ...Utils, Spinner, FlexboxLoader, TriangleLoader, NavigationPromptWrap, VSCOLoader };
export default exDefaujlt;