/* eslint-disable */
import { Component } from 'react';
import { Images, Auth, Utils, withRouter, NavLink, Trans, Http, API, Constant } from 'Helpers';
import { Col, Container, Row, DropdownToggle, DropdownMenu, Dropdown, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import Drawer from 'react-motion-drawer';
import { OnboardModal } from 'Modals';
import { SelectLeague, MoreComponent, SocialForm, NotificationComponents } from 'Components';
import { ReduxHOC } from 'HOC';
import Select from 'react-select';
const {
  REACT_APP_LANDING_PAGE,
  REACT_APP_SETTINGS,
  REACT_APP_SHOW_SSO_HEADER,
  REACT_APP_CLIENT_URL,
  REACT_APP_BASE_URL_PROD
} = process.env
class DefaultHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      SelectLeagueOpen: false,
      ref: null,
      headerHide: false,
      isDrawerOpen: false,
      NotificationsOpen: false,
      MenuOpen: false,
      NotificationOpen: false,
      unread_count: 0,
      notif_total: 0,
      NotificationList: [],
      NotifLoaderShow: false,
      NotificationLoader: true,

      OnboardModalShow: false,
      OnboardModalType: 0,
      SelectedLeague: Utils.getMultiLeg() || {},
    }

    this.node = null;
    this.setInstance = this.setInstance.bind(this)
  }

  setInstance(node) {
    this.node = node
    if (typeof this.props.getInstance === 'function') {
      this.props.getInstance({
        setProps: this.setProps
      })
    }
  }
  leagueDetails = () => {
    Http.post(API.SPORTS_LEAG_LIST).then(res => {
      const { data } = res;
      this.setState({
        SportList: data.sports,
        LeagueList: data.leagues,
      }, () => {
        if (_.size(data.leagues) == 1 || this.props.is_root) {
          // this.setDefaultLeague(data)
        } else {
          this.setState({
            ShowEntireCom: false
          })
        }
        this.setState({
          posting: false
        })
      })
    }).catch(err => {
      console.log(err);
      this.setState({
        posting: false
      })
    })
  }
  setProps = (ref) => {
    this.setState({
      ref: ref.metadata || true,
      headerHide: ref.headerHide === true ? ref.headerHide : false,
    })
  }

  dropdownToggle = (name) => {
    // alert(34)
    // console.log(name,'asdasda')
    this.setState({
      [name]: !this.state[name]
    }, () => {
      if (name == 'SelectLeagueOpen') {
        this.setState({
          SelectedLeague: Utils.getMultiLeg() || {},
        });
      }
    })
  }
  dropdownToggleNF = () => {
    this.setState({
      NotificationOpen: !this.state.NotificationOpen
    }, () => {

      //this.props.history.push('/notification')
    })
  }
  goToNotification = () => {
    this.props.history.push('/notification')
    this.setState({
      NotificationOpen: !this.state.NotificationOpen
    }, () => {

    })
  }

  OnboardModalToggle = (bool, type) => {
    if (bool === true) { document.body.classList.add('disable-scroll') }
    else {
      document.body.classList.remove('disable-scroll')
    }
    this.setState({
      OnboardModalType: type,
      OnboardModalShow: bool
    })
  }

  logout = () => {
    Http.post(API.LOGOUT, {
      session_key: Auth.getAuth()
    }).then(res => {
      this.props.actions.logout()
      Utils.logoutClear()
      // this.props.history.push(Constant.ONBOARD_ROOT);
      this.props.history.push({ pathname: '/' })
    })
  }

  toggleDrawer = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen }, () => {
      if (this.state.isDrawerOpen) {
        document.body.setAttribute('style', `position: fixed; width: 100%; top: 0px`);
      } else {
        document.body.removeAttribute('style');
      }
    });
  }
  gotoLogin = () => {
    if(Utils.getMasterData().allow_sso == '1'){
      this.gotoClienturl(REACT_APP_CLIENT_URL)
    } else {
      this.props.history.push('/login')
    }
  }
  componentDidMount() {
    if (!Utils.getMultiLeg()) {
      this.setState({ SelectLeagueOpen: true })
    }
    if (Auth.getAuth()) {
      this.notificationsCount()
      // this.getNotification();
    }
    this.leagueDetails()

  }
  notificationsCount = () => {
    setTimeout(() => {
      let param = {
        "limit": Constant.ITEMS_PERPAGE_SM,
        "offset": Constant.CURRENT_OFFSET
      }
      Http.post(API.NOTIFICATION_COUNTS, param).then(response => {
        this.setState({
          NotificationLoader: false,
          // NotificationList: response.data.notification_list,
          unread_count: response.data.unread_count,
          notif_total: response.data.offset,
        }, () => {
          // this.getNotification();
          this.setState({
            NotificationListBlank: _.isEmpty(response.data.notification_list)
          })
        });
      }).catch(error => {
        this.setState({ NotificationLoader: false });
      });
    }, 1500)
  }
  componentWillUnmount() {
    // document.body.style.overflow = 'unset';
  }


  clearUnread = () => {
    this.setState({ NotificationLoader: true })
    Http.post(API.UPDATE_READ_ALL).then(response => {
      this.setState({
        NotificationLoader: false,
        unread_count: response.data.unread_count,
      });
    }).catch(error => {
      this.setState({ NotificationLoader: false });
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const Profile = this.props.root.ProfileData;
    const NextProfile = nextProps.root.ProfileData;
    setTimeout(() => {
      if (Auth.getAuth() && Profile.user_id != NextProfile.user_id) {
        this.getNotification()
      }
    }, 100)
  }
  getNotification = () => {
    this.setState({ NotificationLoader: true })
    setTimeout(() => {
      let param = {
        "limit": Constant.ITEMS_PERPAGE_SM,
        "offset": Constant.CURRENT_OFFSET
      }
      Http.post(API.NOTIFICATIONS_DATA, param).then(response => {
        this.setState({
          NotificationLoader: false,
          NotificationList: response.data.notification_list,
          unread_count: response.data.unread_count,
          notif_total: response.data.offset,
        }, () => {
          this.setState({
            NotificationListBlank: _.isEmpty(response.data.notification_list)
          })
        });
      }).catch(error => {
        this.setState({ NotificationLoader: false });
      });
    }, 1500)

  }

  gotoClienturl = (url)=>{
    const originalUrl = REACT_APP_BASE_URL_PROD;
    const encodedUrl = encodeURIComponent(originalUrl);
    const finalUrl = `${url}?cbkurl=${encodedUrl}`;
    //console.log(finalUrl,"finalUrl");
    let status = Utils.isNativeWeb()
    switch(status){
      case 0:
        window.open(finalUrl, "_self")
        break;
      case 1:
        window.mobileApp.nativeLogin()
        break;
      case 2:
        window.webkit.messageHandlers.nativeLogin.postMessage("IOS")
        break;
      default:
        break;
    }
  }
  
componentWillUpdate(nextProps, nextState) {
  const { NotificationOpen } = this.state;
  if(NotificationOpen != nextState.NotificationOpen) {
    this.getNotification();
  }
}
  render() {
    const { i18n, root, location } = this.props;
    const Profile = root.ProfileData;
    const is_sso = Utils.getMasterData().allow_sso;
    //console.log(is_sso,"is_sso");
    const CurrentLineup = root.CurrentLineup;
    const {
      SelectLeagueOpen,
      OnboardModalShow,
      OnboardModalType,
      SelectedLeague,
      MenuOpen,
      headerHide,
      isDrawerOpen,
      NotificationOpen,
      NotificationList,
      unread_count,
      NotificationLoader,
      notif_total
    } = this.state;

    const OnboardModalProps = {
      ...this.props,
      isOpen: OnboardModalShow,
      type: OnboardModalType,
      toggle: this.OnboardModalToggle
    }
    if (headerHide) return null;
    const DrawerProps = {
      noTouchOpen: true,
      handleWidth: 5,
      overlayColor: "rgba(0,0,0,0.78)",
      drawerStyle: {
        "boxShadow": "rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px"
      }
    }
    const MoreComponentProps = {
      onChangeDrawer: this.onChangeDrawer,
      toggleDrawer: this.toggleDrawer
    }
    const NotificationProps = {
      NotificationList
    }
    return (
      <>
        <div className="default-header">
          <Container fluid>
            <Row>
              <div className="hdr-mobile-dimmed" onClick={this.toggleDrawer} />
              <Col className=''>
                <div className="site-logo">
                  <img src={Images.DRAWER_NW} className="header-menu-icn" onClick={this.toggleDrawer} alt='' />
                  <NavLink to={Auth.getAuth() ? Constant.DASHBOARD_PATH : Constant.DEFAULT_ROOT} className="logo-icon">
                    <img src={Images.LOGO_EN} alt="" />
                    {/* <img src={Images.ISL_LOGO} alt="" /> */}
                  </NavLink>
                  {
                  //Utils.getMasterData().allow_sso !== '1' &&
                    <div className='hide-on-bigscr'>
                      {Auth.getAuth() ?

                        <div className="notification-dropdown" data-set="notification">
                          <Dropdown isOpen={NotificationOpen} toggle={() => this.dropdownToggleNF()} className='notification-dropdown'>
                            <DropdownToggle tag='div' className={`notification-trigger ${NotificationOpen ? 'active' : ''}`}>
                              <i className="icon-bell1" />
                              {
                                unread_count != 0 &&
                                <div className="badge-count">{Number(unread_count) > 99 ? '99+' : unread_count}</div>
                              }
                            </DropdownToggle>
                            <DropdownMenu className='notification-content dropdown-menu-right' right={i18n.language == "en" || i18n.language == "en-US"}>
                              <div className="head">
                                <div className="cell">
                                  <NavLink to="/notification" onClick={() => this.dropdownToggleNF()}><Trans>Notifications</Trans></NavLink>
                                </div>
                                {/* <div className="cell">
                                  <UncontrolledDropdown className='notification-menu'>
                                    <DropdownToggle tag='div' className='notification-menu-toggle'>
                                      <i className="icon-others" />
                                    </DropdownToggle>
                                    <DropdownMenu right={i18n.language == "en" || i18n.language == "en-US"} className='dark-dropdown'>
                                      <DropdownItem onClick={() => this.clearUnread()}><Trans>Mark all as read</Trans></DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div> */}
                              </div>
                              <div className="notification-body">
                                <NotificationComponents {...NotificationProps} />
                                {
                                  NotificationList.length > 5 &&
                                  <div className="notification-footer" onClick={() => this.goToNotification()}><Trans>see</Trans></div>
                                }
                              </div>

                            </DropdownMenu>
                          </Dropdown>
                        </div> :
                        <div onClick={() => this.gotoLogin()}>
                          <img src={Images.LOGIN_SIGNUP_HOME} />
                        </div>}
                    </div>}
                </div>


                <div {...{
                  className: ` ${isDrawerOpen ? 'hdr-menu-wrap open' : ''}`
                }}>
                  <div className="hdr-depth-container">

                    <div className="drawer-header">
                      <img src={Images.LOGO_DRAWER} alt="" className="logo" />
                      <i className="icon-close ics-clr" onClick={() => this.toggleDrawer()} />
                    </div>
                    {
                      isDrawerOpen &&
                      <>
                        <div className="league-select-dropdown league-switch-drawer">
                          <div className="league-select-toggle" onClick={() => Utils.getMasterData().allow_sso !== '1' ? [this.dropdownToggle('SelectLeagueOpen'), this.toggleDrawer()] : ''}>
                            <i className="icon-league" />
                            <div className="slt-title"><Trans>LEAGUES</Trans></div>
                            <div className="slt-value">
                              <span>{SelectedLeague.sports_name}:</span>{" "}{SelectedLeague.league_name}
                            </div>
                          </div>
                          {
                            SelectLeagueOpen &&
                            <SelectLeague isOpen={SelectLeagueOpen} dropdownToggle={this.dropdownToggle} />
                          }
                        </div>
                        <MoreComponent {...MoreComponentProps} />
                      </>
                    }


                    <div className="hdr-main-menu">
                      <nav className="global-nav">
                        <NavLink to='/' exact><Trans>Home</Trans></NavLink>
                        {
                          (Auth.getAuth() &&
                            CurrentLineup.user_join_count == 1) &&
                          <>
                            {/* <NavLink to={{ pathname: '/my-team' }} exact><Trans>My Team</Trans></NavLink>
                            {
                              (CurrentLineup.week_info.last_week != CurrentLineup.week_info.active_week) && (Utils.getJoined() !== 0 && Utils.getJoined() !== Utils.getActive()) &&
                              <NavLink to={{ pathname: '/transfers' }} exact><Trans>Transfers</Trans></NavLink>
                            } */}
                            <NavLink to={{ pathname: '/my-team' }} className={window.location.href.includes('transfers') ? 'active' : ''}><Trans>My Team</Trans></NavLink>
                            <NavLink to='/leagues'><Trans>Leagues</Trans></NavLink>
                          </>
                        }
                        <NavLink to='/fixtures' exact><Trans>Fixtures</Trans></NavLink>
                        <NavLink to='/prizes/1' exact><Trans>Prizes</Trans></NavLink>
                        <NavLink to='/statistics'><Trans>Statistics</Trans></NavLink>
                      </nav>
                    </div>
                    <div className="hdr-sub-menu">
                      <ul className="hdr-utility">
                        <li className='league-select-utils'>

                          {/* <Select className='select-league-dd'></Select> */}

                          <div className="league-select-dropdown dropdown-nw">
                            <div className="league-select-toggle" onClick={() => [Utils.getMasterData().IS_MULTILEAGUE !== '0' ? this.dropdownToggle('SelectLeagueOpen') : '']}>
                              {/* <div className="slt-title"><Trans>LEAGUES</Trans></div> */}
                              <div className="slt-value">
                                {/* <span>{SelectedLeague.sports_name}:</span>{" "} */}
                                <i className='icon-league-bn' />

                                {Auth.getAuth() ? SelectedLeague.league_name : <Trans>{SelectedLeague.league_name}</Trans>}

                              </div>
                              {Utils.getMasterData().IS_MULTILEAGUE !== '0' && <i className='icon-down fz-fix' />}
                            </div>
                            {
                              SelectLeagueOpen &&
                              <SelectLeague isOpen={SelectLeagueOpen} dropdownToggle={this.dropdownToggle} />
                            }
                          </div>
                        </li>

                        {
                          Auth.getAuth() ?
                            <>
                              {
                              //Utils.getMasterData().allow_sso !== '1' &&
                                <li>
                                  <div className="notification-dropdown" data-set="notification">
                                    <Dropdown disabled={NotificationLoader} isOpen={NotificationOpen} toggle={() => this.dropdownToggle('NotificationOpen')} className='notification-dropdown'>
                                      <DropdownToggle tag='div' className={`notification-trigger ${NotificationOpen ? 'active' : ''}`}>
                                        <i className="icon-bell1" />
                                        {
                                          unread_count != 0 &&
                                          <div className="badge-count">{Number(unread_count) > 99 ? '99+' : unread_count}</div>
                                        }
                                      </DropdownToggle>
                                      <DropdownMenu className='notification-content' right={i18n.language == "en" || i18n.language == "en-US"}>
                                        <div className="head">
                                          <div className="cell">
                                            <NavLink to="/notification" onClick={() => this.dropdownToggle('NotificationOpen')}><Trans>Notifications</Trans></NavLink>
                                          </div>
                                          <div className="cell">
                                            <UncontrolledDropdown className='notification-menu mark-read-drp'>
                                              <DropdownToggle tag='div' className='notification-menu-toggle'>
                                                <i className="icon-others" />
                                              </DropdownToggle>
                                              <DropdownMenu right={i18n.language == "en" || i18n.language == "en-US"} className='dark-dropdown'>
                                                <DropdownItem onClick={() => this.clearUnread()}><Trans>Mark all as read</Trans></DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </div>
                                        </div>
                                        <div className="notification-body">
                                          <NotificationComponents {...NotificationProps} />
                                          {
                                            NotificationList.length > 5 &&
                                            <NavLink className="notification-footer" to="/notification" onClick={() => this.dropdownToggle('NotificationOpen')}><Trans>see all</Trans></NavLink>
                                          }
                                        </div>

                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>
                                </li>}
                              {is_sso == '0' && <li>
                                <div className="login-profile">
                                  <Dropdown isOpen={MenuOpen} toggle={() => this.dropdownToggle('MenuOpen')} className='login-profile-dropdown'>
                                    <DropdownToggle tag='div' className='login-profile-toggle'>
                                      {/* <div className="details">
                                        {
                                          (Profile.first_name !== '' || Profile.last_name !== '') ?
                                            <div className="user-name">{Profile.first_name + ' ' + Profile.last_name}</div>
                                            :
                                            <div className="user-name text-lowercase m-t">{Profile.user_name}</div>
                                        }
                                        {
                                          g(Profile.first_name !== '' || Profile.last_name !== '') &&
                                          <div className="user-rank">{Profile.user_name}</div>
                                        }
                                      </div> */}
                                      <div className="avtar">
                                        <img src={Profile.image} alt="" />
                                      </div>
                                    </DropdownToggle>
                                    {
                                      <DropdownMenu className='profile-dropdown-content' right={i18n.language == "en" || i18n.language == "en-US"}>
                                        <div className="profile-info-top">
                                          <div className="avtar">
                                            <img src={Profile.image} alt="" />
                                          </div>
                                          <div className="details">
                                            {
                                              (Profile.first_name !== '' || Profile.last_name !== '') ?
                                                <div className="user-name">{Profile.first_name + ' ' + Profile.last_name}</div>
                                                :
                                                <div className="user-name text-lowercase m-t">{Profile.user_name}</div>
                                            }
                                            {
                                              (Profile.first_name !== '' || Profile.last_name !== '') &&
                                              <div className="user-rank">{Profile.user_name}</div>
                                            }
                                          </div>
                                        </div>
                                        {/* <DropdownItem divider /> */}
                                        <DropdownItem onClick={() => this.props.history.push('/profile')}><Trans>My Profile</Trans></DropdownItem>
                                        {/* <DropdownItem onClick={() => this.props.history.push('/notification')}><Trans>Notification</Trans></DropdownItem> */}
                                        {
                                          REACT_APP_SETTINGS == 'true' &&
                                          <DropdownItem onClick={() => this.props.history.push('/settings')}><Trans>Settings</Trans></DropdownItem>
                                        }
                                        <DropdownItem onClick={() => this.logout()}><Trans>Logout</Trans></DropdownItem>
                                      </DropdownMenu>}
                                  </Dropdown>
                                </div>
                              </li>}
                            </>
                            :
                            <li>
                              {
                                ((location.pathname != Constant.ONBOARD_ROOT || REACT_APP_LANDING_PAGE == 'true') && (is_sso == '0' || REACT_APP_SHOW_SSO_HEADER == 1)) &&
                                <>
                                  <span className='border-btn link login-updated' onClick={() => [(is_sso == '1' && REACT_APP_SHOW_SSO_HEADER == 1) ? this.gotoClienturl(REACT_APP_CLIENT_URL) : this.OnboardModalToggle(true, 0)]}><Trans>Login</Trans></span>
                                  <span className='border-btn signUp-updated' onClick={() => [(is_sso == '1' && REACT_APP_SHOW_SSO_HEADER == 1) ? this.gotoClienturl(REACT_APP_CLIENT_URL) : this.OnboardModalToggle(true, 1)]}><Trans>Sign Up</Trans></span>
                                </>
                              }
                            </li>
                        }
                      </ul>
                    </div>
                  </div>
                </div>

              </Col>
            </Row>
          </Container>
        </div >
        {
          // !Auth.getAuth() &&
          < OnboardModal {...OnboardModalProps} />
        }
        <div className="visibility-hide">
          <SocialForm />
        </div>
      </>
    );
  }
}

const DefaultHeaderWrap = withRouter(DefaultHeader, { withRef: true })
export default ReduxHOC(DefaultHeaderWrap);