/* eslint-disable eqeqeq, react/no-direct-mutation-state, jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
import { Component } from 'react';
import { Container, Row, Col } from 'reactstrap'
import { ProfileForm, ProfileView } from 'Components';
import { Http, API, Utils, Auth, _, Trans } from 'Helpers';
import { ReduxHOC } from 'HOC';

class MyProfile extends Component {
  constructor(props) {
    super(props)
    this.nodes = {}
    this.state = {
      isEditProfile: false,
      UserProfile: {},
      PicPosting: false,
      isLoadingComplete: false,
      unread_count: 0,
      form_validate: false
    }
  }
  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node
    }
  }

  _updateProfileData = () => {
    const { actions } = this.props
    actions.setProfileData(Utils.getProfile() || {})
  }

  ViewToggle = (bool = true, form_validate = false) => {
    this.setState({
      isEditProfile: bool,
      form_validate: form_validate
    }, () => {
      if (!bool) {
        this.nodes.ProfileView.ProfileViewUpdate(this.state.UserProfile);
      }
    })
  }
  SelectRegion = (type, data) => {
    const { master_country_id, master_state_id } = this.state.UserProfile
    let name = null
    let label_name = null
    let master_id = null

    if (type == 'country_list') {
      name = 'country'
      label_name = 'master_country_id'
      master_id = master_country_id
    } else {
      name = 'state'
      label_name = 'master_state_id'
      master_id = master_state_id
    }
    _.map(data, item => {
      if (item[label_name] == master_id) {
        this.setState({
          UserProfile: { ...this.state.UserProfile, [name]: item }
        })
      }
      return item;
    })
  }
  GetMyProfile = () => {
    Http.post(API.GET_MY_PROFILE).then(response => {
      const gender_list = [
        { id: 'male', name: <Trans>Male</Trans> },
        { id: 'female', name: <Trans>Female</Trans> },
        { id: 'other', name: <Trans>Other</Trans> },
        { id: 'doesnt_specify', name: <Trans>Doesn't wish to specify</Trans> }
      ]

      for (var key in response.data.user_profile) {
        if (response.data.user_profile[key] == null) {
          response.data.user_profile[key] = ''
        }
      }

      Utils.setProfile(response.data.user_profile);
      this.setState({
        UserProfile: { ...response.data.user_profile, country: {}, state: {}, gender_list: gender_list },
        isLoadingComplete: true
      }, () => {
        this._updateProfileData()
        this.getSelectData('country_list');
        if (this.state.UserProfile.master_country_id) {
          this.getSelectData('state_list', this.state.UserProfile.master_country_id)
        }
      })
    }).catch(error => {

    });
  }
  getSelectData = (type, master_country_id = null) => {
    let param = {
      "master_country_id": master_country_id
    }
    let s3_param = [
      API.S3_GET_COUNTRY_LIST,
    ]

    if (type === 'country_list') {
      Http.s3(Utils.s3UrlCtConvert(s3_param), API.GET_COUNTRY_LIST).then(response => {
        this.setState({
          [type]: response.data[type],
          posting: false
        }, () => {
          this.SelectRegion(type, this.state[type])
        });
      }).catch(error => {
//        return

        this.setState({ posting: false });
      });
    }
    else {
      Http.post(API.GET_STATE_LIST, param).then(response => {
        this.setState({
          [type]: response.data[type],
          posting: false
        }, () => {
          this.SelectRegion(type, this.state[type])
        });
      }).catch(error => {

        this.setState({ posting: false });
      });
    }
  }
  UpdateProfile = (data) => {
    let Keys = _.keys(data)
    _.map(Keys, item => {
      this.state.UserProfile[item] = data[item]
    })
    this.SelectRegion('country_list', this.state.country_list)
    this.setState({
      state_list: data.state_list
    }, () => {
      this.SelectRegion('state_list', this.state.state_list)
    });


    // this.SelectRegion('state_list', data.state_list)
    // console.log(this.state.state_list)
    // if(_.isEmpty(this.state.state_list)) {
    //   if(this.state.UserProfile.master_country_id) {
    //     this.getSelectData('state_list', this.state.UserProfile.master_country_id)
    //   }
    // }
    setTimeout(() => {
      this.setState({
        UserProfile: this.state.UserProfile
      }, () => {
        this.setState({ isEditProfile: false })
        Utils.setProfile(this.state.UserProfile);
        setTimeout(() => {
          this._updateProfileData()
        }, 100)
        this.nodes.ProfileView.ProfileViewUpdate(this.state.UserProfile);
      })
    }, 500)
  }

  componentDidMount() {
    //console.log(this.props, 'PROPS');
    this.GetMyProfile()
    // this.getNotification()
    window.addEventListener('scroll', this.listenToScroll)
  }

  onDrop = (event) => {
    let file = event.target.files[0];
    Utils.readFile(file)
      // .then(Utils.createImage)
      .then(Utils.rotate(undefined, file.type))
      .then(blob => {
        // blob.name = file.name;
        // console.log(file)
        this.uploadImage(file)
      })
  }

  uploadImage(blob) {
    let globalThis = this;
    const { UserProfile } = this.state

    this.setState({ PicPosting: true })
    var data = new FormData();
    data.append("userfile", blob);
    // data.append("userfile", this.state.file);

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState == 4) {
        var response = JSON.parse(this.responseText);

        if (response != '' && response.response_code == 200) {
          UserProfile.image = response.data.image_path
          globalThis.setState({
            UserProfile: UserProfile
          }, () => globalThis._updateProfile())
        }
        else {
          if (response.global_error && response.global_error != '') {
            Utils.notify(response.global_error, "error");
          }
          else {
            var keys = Object.keys(response.error);
            if (keys.length > 0) {
              Utils.notify(response.error.userfile, "error");
            }
          }

          globalThis.setState({ PicPosting: false })
        }

      }
    });

    xhr.open("POST", API.DO_UPLOAD);
    xhr.setRequestHeader('session_key', Auth.getAuth())
    xhr.send(data);
  }

  _updateProfile = () => {
    const { isEditProfile, UserProfile } = this.state;
    if (isEditProfile) {
      this.nodes.ProfileForm.ProfileImageUpdate(UserProfile.image);
      this.setState({ PicPosting: false });
      return;
    }
    const {
      first_name,
      last_name,
      user_name,
      phone_no,
      email,
      dob,
      address,
      master_country_id,
      master_state_id,
      gender,
      city,
      team_id,
      image,
      zip_code } = UserProfile;
      const lastSegment = image.split("/").pop();
    let param = {
      "first_name": first_name,
      "last_name": last_name,
      "email": email,
      "user_name": user_name,
      "phone_no": phone_no,
      "address": address,
      "city": city,
      "zip_code": zip_code,
      "team_id": team_id,

      "dob": dob,
      "gender": gender,
      "master_country_id": master_country_id,
      "master_state_id": master_state_id,
      "image": lastSegment,
    }
    Http.post(API.UPDATE_PROFILE, param).then(response => {
      Utils.notify(response.message);
      response.data.user_profile.dob = Utils.DobFormat(response.data.user_profile.dob)
      // this.UpdateProfile(response.data.user_profile)
      let _UserProfile = this.state.UserProfile
      UserProfile[image] = response.data.user_profile.image;

      Utils.setProfile(_UserProfile);
      this.setState({ UserProfile: _UserProfile }, () => {
        this._updateProfileData()
      })
      this.setState({ PicPosting: false });
    }).catch(error => {
      this.ViewToggle(true, error)
      this.setState({ PicPosting: false });
    });
  }

  getNotification = () => {
    let params = {
      "limit": "3",
      "offset": "0"
    }
    this.setState({ isNotifLoaderShow: true })
    Http.post(API.NOTIFICATIONS_DATA, params).then(response => {
      this.setState({
        unread_count: response.data.unread_count
      })
    }).catch(error => {
      this.setState({ isNotifLoaderShow: false })
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
    this.setState = () => {
      return;
    };
  }
  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    // const height =
    //   document.documentElement.scrollHeight -
    //   document.documentElement.clientHeight

    const scrolled = winScroll
    // const scrolled = winScroll / height

    this.setState({
      theposition: scrolled,
    })
  }
  render() {
    const { isEditProfile, UserProfile, country_list, state_list, PicPosting, isLoadingComplete, form_validate,
      // unread_count, 
      theposition } = this.state;
    const { first_name, last_name, user_name, image, point_balance } = UserProfile;

    const ProfileFormProps = {
      form_validate,
      ...UserProfile,
      country_list: country_list,
      state_list: state_list,
      UpdateProfile: this.UpdateProfile,
      ViewToggle: this.ViewToggle
    }

    return (
      <Container>
        <Row className='zero-pd'>
          <Col>
            <div className="profile-container">
              <div className="profile-top-area">
                {/* <div className={`profile-toolbar ${theposition > 15 ? 'white' : ''}`}>
                  <div className="app-toolbar-bottom">
                    {
                      !isEditProfile &&
                      <i className="icn icon-arrow-back" onClick={() => this.props.history.goBack()} />
                    }
                    {
                      !isEditProfile && isLoadingComplete &&
                      <i className="icn right editicon pointer" onClick={() => this.ViewToggle()} ></i>
                    }

                    <div className="center-toolbar">
                      <div className="header-title-sm"><Trans>Profile</Trans></div>
                    </div>
                  </div>
                </div> */}
                <span className='top-bg'></span>
                {
                  !isEditProfile && isLoadingComplete &&
                  <a className="icon-edit icnedit" onClick={() => this.ViewToggle()} />
                }
                {
                  isEditProfile &&
                  <a className={`profile-back-btn icon-arrow-back ${theposition > 15 ? 'white' : ''}`} onClick={() => this.ViewToggle(false)} />
                }
                <div className="profile-details-wrap">

                  <div className="profile-avtar">
                    {
                      PicPosting &&
                      <Utils.Spinner />
                    }
                    <img src={image} alt="" />

                    <a className="camera-ic" onClick={() => { this.upload.click() }} />


                    <input id="ProfilePicture"
                      disabled={PicPosting}
                      type="file"
                      accept="image/*"
                      ref={(ref) => this.upload = ref}
                      style={{ display: 'none' }}
                      onChange={(e) => this.onDrop(e)}
                    />
                  </div>
                  <div className="fullname">{`${first_name || ''} ${last_name || ''}`}</div>
                  <div className="username">{user_name}</div>
                </div>
              </div>
              {
                isEditProfile ?
                  <ProfileForm getInstance={this.setNodeRef.bind(this, 'ProfileForm')} {...ProfileFormProps} />
                  :
                  <ProfileView getInstance={this.setNodeRef.bind(this, 'ProfileView')} ViewToggle={this.ViewToggle} data={UserProfile} />
              }

            </div>

          </Col>
        </Row>
      </Container>
    )
  }
}

export default ReduxHOC(MyProfile)